import React, { useEffect, useState, useContext } from "react";
import { TreeView, TreeItem, treeItemClasses } from "@mui/x-tree-view";
import styles from "./_tree.module.css";
import { Box, Modal, SvgIcon } from "@mui/material";
import { useSpring, animated } from "@react-spring/web";
import Collapse from "@mui/material/Collapse";
import PropTypes from "prop-types";
import { alpha, styled } from "@mui/material/styles";
import { PostContext } from "./VideoContext";
import Styles from "../_body.module.css";
import styl from "../user/_mainUser.module.css";
import "./listdrawer.css";
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Fade,
  Typography,
  Tooltip,
  Grid,
} from "@mui/material";
import { Link } from "react-router-dom";
import AxiosInstance from "../../api/AxiosInstance";
import toast from "react-hot-toast";

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      <svg
        width="26"
        height="26"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#24AAE3"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M6 9l6 6 6-6" />
      </svg>
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      tslint:disable-next-line: max-line-length
      <svg
        width="26"
        height="26"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#24AAE3"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 18l6-6-6-6" />
      </svg>
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  in: PropTypes.bool,
};

const StyledTreeItem = styled(props => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 15,
    paddingLeft: 4,
    borderLeft: `1px solid ${alpha(theme.palette.text.primary, 0.3)}`,
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth < 520 ? "90%" : "60%",
  bgcolor: "background.paper",
  border: "1px solid #02476d",
  boxShadow: 24,
  p: 1,
  borderRadius: "6px",
};
function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      <svg
        width="26"
        height="26"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#24AAE3"
        strokeWidth="2.5"
        strokeLinecap="butt"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="8" y1="12" x2="16" y2="12"></line>
      </svg>
    </SvgIcon>
  );
}

const UserVideoList = () => {
  // Video Modal
  const [open, setOpen] = React.useState(false);

  const handleClose = () => setOpen(false);
  let {
    features,
    level1,
    level2,
    level3,
    loadedFeatures,
    loadedLevel1,
    loadedLevel2,
    loadedLevel3,
    fetchLevel3,
    fetchLevel2,
    fetchVideo,
    fetchLevel1,
    fetchFeature,
    setVideos,
    loadPage,
    role,
    appValue,
    parsedData,
  } = useContext(PostContext);

  //! Organization and its Logic ends
  let [ready, setReady] = useState(false);

  let [term, setTerm] = useState("");
  let [display, setDisplay] = useState(null);
  const [loadSearch, setLoadSearch] = useState(false);

  let [userData, setUserData] = useState([]);
  let [defaultUserData, setDefaultUserData] = useState([]);

  const [pagination, setPagination] = useState({
    limit: 10,
    page: 1,
    load: false,
    length: 1,
  });

  const [searchTerm, setSearchTerm] = useState("");
  // const [loadSearch, setLoadSearch] = useState(false);
  const [filtered, setFiltered] = useState({ x: false, term: "" });
  const handleReset = () => {
    setUserData(defaultUserData.data);
    setDisplay(null);
    setFiltered({ x: false, term: "" });

    setPagination({
      ...pagination,
      length: defaultUserData.total_length,
      load: defaultUserData.total_length > 10 ? false : null,
      page: 1,
    });
    setSearchTerm("");
  };

  const handleSearch = async () => {
    if (searchTerm.length > 2) {
      setLoadSearch(true);
      try {
        const { data } = await AxiosInstance.get(
          `/video/getKeywordUser?keyword=${searchTerm}`,
          {
            headers: {
              Authorization: `Bearer ${parsedData.TOKEN}`,
            },
          }
        );
        setUserData(data === null ? [] : data);
        setDisplay(data === null ? [] : data);
        setPagination({
          ...pagination,
          length: data.total_length,
          load: data.total_length > 10 ? false : null,
          page: 1,
        });
        setLoadSearch(false);
        setFiltered({ x: true, term: searchTerm });
      } catch (error) {
        setLoadSearch(false);
        toast.error(error?.response?.data?.error || "something went wrong");
      }
    } else {
      toast.error("search with minimum 3 characters");
      setLoadSearch(false);
    }
  };

  let drawerClass = [];
  let mainClass = [];
  if (ready) {
    drawerClass.push("drawerOpen");
    mainClass.push("mainOpen");
  } else {
    drawerClass = [];
    mainClass.push("mainClose");
  }
  // To Fetch all the apis
  useEffect(() => {
    let checkService = async () => {
      try {
        // await AxiosInstance.get(``);
        setReady(true);
      } catch (error) {
        console.error(error);
      }
    };
    checkService();
  }, []);

  //Tree view call backs
  const handleSelect = (x, y) => {
    setUserData(defaultUserData.data);
    setDisplay(null);
    setFiltered({ x: false, term: "" });
    const nodeName = y.split("@@")[1].split("#")[0];
    if (nodeName === "role" && !loadedFeatures.includes(y)) {
      const nodeId = y.split("@@")[1].split("#")[1];
      fetchFeature(y, nodeId);
      setVideos({ data: [], level: false, selected: "" });
    } else if (nodeName === "feature" && !loadedLevel1.includes(y)) {
      setVideos({ data: [], level: false, selected: "" });

      const feature = y.split("@@")[2].split("#")[1];
      const featureId = y.split("@@")[1].split("#")[1];
      fetchLevel1(y, feature, featureId);
      fetchVideo(y, feature, featureId, "features");
    } else if (nodeName === "level1" && !loadedLevel2.includes(y)) {
      setVideos({ data: [], level: false, selected: "" });
      const feature = y.split("@@")[3].split("#")[1];
      const featureId = y.split("@@")[2].split("#")[1];
      const id1 = y.split("@@")[1].split("#")[1];
      const subName = y.split("@@")[4].split("#")[1];
      fetchLevel2(y, featureId, feature, id1);
      fetchVideo(y, feature, id1, "subFeatures");
    } else if (nodeName === "level2" && !loadedLevel3.includes(y)) {
      setVideos({ data: [], level: false, selected: "" });
      const feature = y.split("@@")[4].split("#")[1];
      const featureId = y.split("@@")[3].split("#")[1];
      const id1 = y.split("@@")[2].split("#")[1];
      const id2 = y.split("@@")[1].split("#")[1];
      const subName = y.split("@@")[5].split("#")[1];
      fetchLevel3(y, feature, featureId, id1, id2);
      fetchVideo(y, feature, id2, "subFeatures1");
    } else if (nodeName === "level3" && !loadedLevel1.includes(y)) {
      setVideos({ data: [], level: false, selected: "" });
      const feature = y.split("@@")[5].split("#")[1];
      const featureId = y.split("@@")[4].split("#")[1];
      const id1 = y.split("@@")[3].split("#")[1];
      const id2 = y.split("@@")[2].split("#")[1];
      const id3 = y.split("@@")[1].split("#")[1];

      const subName = y.split("@@")[6].split("#")[1];
      fetchLevel3(y, feature, featureId, id1, id2, id3);
      fetchVideo(y, feature, id3, "subFeatures2");
    } else if (nodeName === "role" && loadedFeatures.includes(y)) {
      setVideos({ data: [], level: false, selected: "" });
    } else if (nodeName === "feature" && loadedLevel1.includes(y)) {
      setVideos({ data: [], level: false, selected: "" });
      const featureName = y.split("@@")[1].split("#")[1];
      const feature = y.split("@@")[2].split("#")[1];
      fetchVideo(y, feature, featureName, "features");
    } else if (nodeName === "level1" && loadedLevel2.includes(y)) {
      // //("loadedfea11111");
      const featureId = y.split("@@")[1].split("#")[1];

      setVideos({ data: [], level: false, selected: "" });
      const feature = y.split("@@")[3].split("#")[1];

      fetchVideo(y, feature, featureId, "subFeatures");
    } else if (nodeName === "level2" && loadedLevel3.includes(y)) {
      const featureId = y.split("@@")[1].split("#")[1];
      setVideos({ data: [], level: false, selected: "" });
      const feature = y.split("@@")[4].split("#")[1];

      fetchVideo(y, feature, featureId, "subFeatures1");
    } else if (nodeName === "level3" && loadedLevel1.includes(y)) {
      const featureId = y.split("@@")[1].split("#")[1];
      setVideos({ data: [], level: false, selected: "" });
      const feature = y.split("@@")[5].split("#")[1];

      fetchVideo(y, feature, featureId, "subFeatures2");
    }
  };
  const [expanded, setExpanded] = useState([]);
  // filter preview video
  let [openModel1, setOpenModel1] = useState(false);
  let [modalState, setModalState] = useState(null);
  const handleImageModelOpen1 = evt => {
    setOpenModel1(true);
    setModalState(evt);
  };
  const handleImageModelClose1 = () => {
    setOpenModel1(false);
    setModalState(null);
  };
  return (
    <div className={styles.treeContainer}>
      <Modal
        open={openModel1}
        onClose={handleImageModelClose1}
        closeAfterTransition
        // slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModel1}>
          <Box sx={style}>
            <aside
              style={{
                width: "100%",
                minHeight: "120px",
                height: "50vh",
                position: "relative",
              }}
            >
              <button
                onClick={handleImageModelClose1}
                className={Styles.closeButton}
                style={{
                  position: "absolute",
                  right: "-25px",
                  top: "-15px",
                  border: "none",
                  background: "transparent",
                }}
              >
                <svg width="18.54" height="20" viewBox="0 0 1216 1312">
                  <path
                    fill="crimson"
                    d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                  />
                </svg>
              </button>
              {display === null ? (
                "Loading Video..."
              ) : (
                <video
                  controls
                  autoPlay
                  width="100%"
                  style={{ height: "inherit" }}
                  controlsList="nodownload"
                  disablePictureInPicture
                >
                  <source
                    src={`https://wikib.qspiders.com${display[0]?.path}`}
                  />
                </video>
              )}
            </aside>
          </Box>
        </Fade>
      </Modal>
      <div className="listApp">
        <nav style={{ zIndex: "999", marginTop: "0vh" }}>
          {/* <span> */}
          <Tooltip
            title=" Click for Drawer's Menu to Expand/Collapse"
            disableFocusListener
            disableTouchListener
            arrow
          >
            <span
              className="icon-wrapper"
              onClick={() => {
                setReady(!ready);
              }}
            >
              <svg className="icon" viewBox="0 0 448 512">
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
            </span>
          </Tooltip>
          <span className={Styles.SearchBlock}>
            <div style={{ display: "flex" }}>
              {" "}
              <Grid item md={2} position="relative">
                <span className={Styles.searchIcon}>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="#fff"
                    stroke="#02476d"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </span>
                <input
                  placeholder=" Search Keyword"
                  type="search"
                  className={Styles.studentNameList}
                  value={searchTerm}
                  onChange={e => {
                    setSearchTerm(e.target.value);
                  }}
                />
              </Grid>
              <Grid item alignItems="center">
                &nbsp;
                <Button
                  size="small"
                  color="warning"
                  sx={{
                    background: "linear-gradient(to right, #02476d, #01598a)",
                    color: "#fff",
                    fontWeight: "bold",
                    "&:hover": {
                      background: "linear-gradient(to right, #0a3147, #035582)",
                      color: "#fff",
                    },
                  }}
                  variant="contained"
                  onClick={handleSearch}
                >
                  Search
                </Button>
                &nbsp;&nbsp;
                {filtered.x && (
                  <Button
                    sx={{ fontWeight: "bold" }}
                    size="small"
                    onClick={handleReset}
                    color="error"
                    variant="contained"
                  >
                    Reset
                  </Button>
                )}
              </Grid>
            </div>
            <ul style={{ listStyle: "none", color: "black" }}>
              {" "}
              {loadSearch ? (
                <li>Searching...</li>
              ) : term && term?.length < 3 ? (
                <li>Enter Minimum 3 Character to show Result</li>
              ) : display !== null && display?.length <= 0 ? (
                <li
                  style={{
                    background: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "5px",
                    fontWeight: "bold",
                  }}
                >
                  No Result
                </li>
              ) : (
                display &&
                display?.map((val, ind) => {
                  return (
                    <Link aria-disabled key={ind + 1}>
                      <li
                        style={{
                          textTransform: "capitalize",
                        }}
                        onClick={() => {
                          handleImageModelOpen1(val);
                        }}
                      >
                        <div>
                          {" "}
                          <Card
                            key={ind + 1}
                            style={{ width: "100%", display: "flex" }}
                          >
                            <CardMedia
                              sx={{
                                position: "relative",
                                height: 60,
                                width: 60,
                                backgroundImage: `url("https://wikib.qspiders.com${val?.thumbnail}")`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                              }}
                              title="Play Video"
                            >
                              <Button
                                disableFocusRipple
                                disableRipple
                                disableTouchRipple
                                sx={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              >
                                <svg width="20" height="20" viewBox="0 0 32 32">
                                  <path
                                    fill="#fff"
                                    d="M11 23a1 1 0 0 1-1-1V10a1 1 0 0 1 1.447-.894l12 6a1 1 0 0 1 0 1.788l-12 6A1.001 1.001 0 0 1 11 23"
                                  />
                                  <path
                                    fill="orangered"
                                    d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2m7.447 14.895l-12 6A1 1 0 0 1 10 22V10a1 1 0 0 1 1.447-.894l12 6a1 1 0 0 1 0 1.788"
                                  />
                                </svg>
                              </Button>
                            </CardMedia>
                            <CardContent>
                              <Typography
                                gutterBottom
                                variant="body2"
                                component="div"
                              >
                                <b style={{ color: "#02476d" }}>Name</b> :{" "}
                                {val.videoname}
                              </Typography>
                            </CardContent>
                          </Card>
                        </div>
                      </li>
                    </Link>
                  );
                })
              )}
            </ul>
          </span>
          {/* </span> */}
          <div className="navContent">
            {/* <h4>{pageTitles[pageInfo] || null}</h4> */}
            {/* <Fab variant="extended" onClick={() => setListSide(true)}>
            Home
          </Fab>
          <Fab variant="extended" onClick={() => setListSide(false)}>
            List
          </Fab> */}
          </div>
        </nav>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          style={{ maxWidth: "80%", margin: "auto" }}
        >
          <Box sx={style}>
            <section
              style={{
                padding: "0 5vw",
              }}
            >
              <button onClick={handleClose} className={styl.closeButton}>
                <svg width="18.54" height="20" viewBox="0 0 1216 1312">
                  <path
                    fill="crimson"
                    d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                  />
                </svg>
              </button>
            </section>
          </Box>
        </Modal>
        <aside className={drawerClass.join(" ")}>
          <div className={styles.treeContainer}>
            {features !== null && (
              <TreeView
                aria-label="customized"
                className="treeCont"
                defaultCollapseIcon={<MinusSquare />}
                defaultExpandIcon={<PlusSquare />}
                defaultEndIcon={<CloseSquare />}
                sx={{
                  flexGrow: 1,
                }}
                expanded={expanded}
                onNodeToggle={(a, b) => {
                  const action = b.length > expanded.length ? true : false;
                  if (action) {
                    const previousArray = expanded;
                    const newValue =
                      expanded.length > 0
                        ? b.filter(e => !previousArray.includes(e))[0]
                        : b[0];
                    handleSelect("x", newValue);
                    const modifiedArray =
                      newValue === ""
                        ? b
                        : b.filter(
                            e =>
                              !e.includes(
                                `@@${newValue.split("@@")[1].split("#")[0]}#`
                              )
                          );
                    setExpanded([...modifiedArray, newValue]);
                  } else {
                    const newValue = expanded.filter(e => !b.includes(e))[0];
                    const nodeName = newValue.split("@@")[1].split("#")[0];
                    if (nodeName === "role") {
                      setVideos({ data: [], level: false, selected: "" });
                    } else if (nodeName === "feature") {
                      setVideos({ data: [], level: false, selected: "" });
                      handleSelect("x", newValue);
                    } else if (nodeName === "level1") {
                      setVideos({ data: [], level: false, selected: "" });
                      handleSelect("x", newValue);
                    } else if (nodeName === "level2") {
                      setVideos({ data: [], level: false, selected: "" });
                      handleSelect("x", newValue);
                    } else if (nodeName === "level3") {
                      setVideos({ data: [], level: false, selected: "" });
                      handleSelect("x", newValue);
                    }
                    setExpanded(b);
                  }
                }}
                onNodeSelect={(x, y) => {
                  const nodeName = y.split("@@")[1].split("#")[0];
                  if (nodeName === "level3") {
                    handleSelect("x", y);
                  }
                }}
              >
                <header>
                  <ul style={{ listStyle: "none" }}>
                    <li
                      style={{
                        fontSize: "20px",
                        width: "100%",
                        textAlign: "center",
                        textTransform: "capitalize",
                      }}
                    >
                      {appValue}
                    </li>
                    {/* <li className={styles.Heading}>Qtalk</li> */}
                  </ul>
                </header>
                <br />

                {!loadPage &&
                features !== null &&
                Object.keys(features).length > 0
                  ? role.map((ele, ind) => {
                      return (
                        <StyledTreeItem
                          key={`role-${ele}`}
                          nodeId={`roles@@role#${ele}`}
                          label={
                            <div
                              className={styles.treeItemLabel}
                              // onMouseEnter={() => {
                              //   setSelectedRole(ele);
                              // }}
                            >
                              <span className={styles.lablename}>Role</span>{" "}
                              {ele}
                            </div>
                          }
                        >
                          {features[ele].load
                            ? "Loading..."
                            : features[ele].load !== null
                            ? features[ele].data.length > 0
                              ? features[ele].data.map((fea, inf) => {
                                  const idx = fea["_id"];
                                  return (
                                    <StyledTreeItem
                                      key={`feature@@feature#${idx}@@role#${ele}@@name#${fea.name}`}
                                      nodeId={`feature@@feature#${idx}@@role#${ele}@@name#${fea.name}`}
                                      label={
                                        <div className={styles.treeItemLabel}>
                                          <span className={styles.lableFeature}>
                                            Feature
                                          </span>{" "}
                                          {fea.name}
                                        </div>
                                      }
                                    >
                                      {level1
                                        ? level1[idx].loading
                                          ? "Loading..."
                                          : level1[idx].loading !== null
                                          ? level1[idx].data.length > 0
                                            ? level1[idx].data.map(
                                                (lv, inl) => {
                                                  // //(lv)
                                                  const idx1 = lv["_id"];
                                                  return (
                                                    <StyledTreeItem
                                                      key={`level1@@level1#${idx1}@@feature#${idx}@@role#${ele}@@name#${lv.name}`}
                                                      nodeId={`level1@@level1#${idx1}@@feature#${idx}@@role#${ele}@@name#${lv.name}`}
                                                      label={
                                                        <div
                                                          className={
                                                            styles.treeItemLabelsub1
                                                          }
                                                        >
                                                          <span
                                                            className={
                                                              styles.lablelevel111
                                                            }
                                                          >
                                                            Subfeature 1
                                                          </span>{" "}
                                                          {lv.name}
                                                        </div>
                                                      }
                                                    >
                                                      {level2
                                                        ? level2[idx1].loading
                                                          ? "Loading..."
                                                          : level2[idx1]
                                                              .loading !== null
                                                          ? level2[idx1].data
                                                              .length > 0
                                                            ? level2[
                                                                idx1
                                                              ].data.map(
                                                                (lv2, inl2) => {
                                                                  const idx2 =
                                                                    lv2["_id"];
                                                                  // //(lv2,"level2")

                                                                  return (
                                                                    <StyledTreeItem
                                                                      key={`level2@@level2#${idx2}@@level1#${idx1}@@feature#${idx}@@role#${ele}@@name#${lv2.name}`}
                                                                      nodeId={`level2@@level2#${idx2}@@level1#${idx1}@@feature#${idx}@@role#${ele}@@name#${lv2.name}`}
                                                                      label={
                                                                        <div
                                                                          className={
                                                                            styles.treeItemLabelsub2
                                                                          }
                                                                        >
                                                                          <span
                                                                            className={
                                                                              styles.lablelevel2
                                                                            }
                                                                          >
                                                                            Subfeature
                                                                            2
                                                                          </span>{" "}
                                                                          {
                                                                            lv2.name
                                                                          }
                                                                        </div>
                                                                      }
                                                                    >
                                                                      {level3 &&
                                                                      level3[
                                                                        idx2
                                                                      ]
                                                                        ? level3[
                                                                            idx2
                                                                          ]
                                                                            .loading
                                                                          ? "Loading..."
                                                                          : level3[
                                                                              idx2
                                                                            ]
                                                                              .loading !==
                                                                            null
                                                                          ? level3[
                                                                              idx2
                                                                            ]
                                                                              .data
                                                                              .length >
                                                                            0
                                                                            ? level3[
                                                                                idx2
                                                                              ].data.map(
                                                                                (
                                                                                  lv3,
                                                                                  inl3
                                                                                ) => {
                                                                                  const idx3 =
                                                                                    lv3[
                                                                                      "_id"
                                                                                    ];
                                                                                  return (
                                                                                    <StyledTreeItem
                                                                                      key={`level3@@level3#${idx3}@@level2#${idx2}@@level1#${idx1}@@feature#${idx}@@role#${ele}@@name#${lv3.name}`}
                                                                                      nodeId={`level3@@level3#${idx3}@@level2#${idx2}@@level1#${idx1}@@feature#${idx}@@role#${ele}@@name#${lv3.name}`}
                                                                                      label={
                                                                                        <div
                                                                                          className={
                                                                                            styles.treeItemLabelsub3
                                                                                          }
                                                                                        >
                                                                                          <span
                                                                                            className={
                                                                                              styles.lablelevel3
                                                                                            }
                                                                                          >
                                                                                            Subfeature
                                                                                            3
                                                                                          </span>{" "}
                                                                                          {
                                                                                            lv3.name
                                                                                          }
                                                                                        </div>
                                                                                      }
                                                                                    ></StyledTreeItem>
                                                                                  );
                                                                                }
                                                                              )
                                                                            : "No data"
                                                                          : level3[
                                                                              idx2
                                                                            ]
                                                                              .error
                                                                        : "No data"}
                                                                    </StyledTreeItem>
                                                                  );
                                                                }
                                                              )
                                                            : "No data"
                                                          : level2[idx1].error
                                                        : "No data"}
                                                    </StyledTreeItem>
                                                  );
                                                }
                                              )
                                            : "No data"
                                          : level1[idx].error
                                        : "No data"}
                                    </StyledTreeItem>
                                  );
                                })
                              : "No data"
                            : features[ele].error}
                        </StyledTreeItem>
                      );
                    })
                  : "Loading..."}
              </TreeView>
            )}
          </div>
        </aside>
      </div>
    </div>
  );
};

export default UserVideoList;
