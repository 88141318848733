import { createContext, useState, useEffect } from "react";
import AxiosInstance from "../../api/AxiosInstance";
export const PostContext = createContext();
export const PostContextProvideradmin = ({ children }) => {
  const [appData, setAppData] = useState([]);

  // role application fetch
  let user1 = sessionStorage.getItem("user");
  let user2 = JSON.parse(user1);
  let user = user2?.data;
  useEffect(() => {
    const obj = {};
    appData.forEach(e => {
      obj[e] = {
        data: [],
        load: false,
        loadMore: false,
        error: null,
        length: 0,
      };
    });

    setRoleData(obj);
  }, [appData]);

  const [RoleData, setRoleData] = useState(null);
  const [loadedRole, setLoadedRole] = useState([]);

  //   let role = user.role;
  useEffect(() => {
  
    let fetchrole = async () => {
      try {
        await AxiosInstance.get(`/users/getApplication`, {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        })
          .then(data => {
            let roledata =
              user.role[0] === "Admin"
                ? data?.data.getApp
                : data?.data?.getApplication;
            if (typeof roledata === "string") {
              setAppData([roledata]);
            } else {
              setAppData(roledata);
            }
          })
          .catch(y => {
            //(y);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchrole();
  }, []);
  //   useEffect(() => {
  const fetchRoleData = async (layer, roleData) => {
    const obj = { ...RoleData };
    obj[roleData].load = true;

    try {
      await AxiosInstance.get(`/users/getRole`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let roledata = data?.data?.getrole;
          obj[roleData].data = roledata;
          obj[roleData].length = data?.data?.count;
          obj[roleData].load = false;

          setRoleData(obj);
          setLoadedRole([...loadedRole, layer]);
          if (roledata.length > 0) {
            setFeatures(
              createLayer(
                features,
                roledata?.map(e => e)
              )
            );
          }
        })
        .catch(y => {
          //(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
      const obj = { ...features };
      obj[roleData].load = null;
      obj[roleData].error = "Unable to fetch";
      setRoleData(obj);
    }
  };

  const createLayer = (state, newKeys) => {
    // //("layer", state, newKeys);
    if (state) {
      const uniqueKeys = newKeys.filter(e => !Object.keys(state).includes(e));
      const obj = {};
      uniqueKeys.forEach(e => {
        obj[e] = {
          data: [],
          loading: false,
          loadMore: false,
          error: null,
          length: 0,
          videos: [],
          loadMoreVideo: false,
          videoLength: 0,
        };
      });
      return { ...state, ...obj };
    } else {
      const obj = {};
      newKeys.forEach(e => {
        obj[e] = {
          data: [],
          loading: false,
          loadMore: false,
          error: null,
          length: 0,
          videos: [],
          videoLength: 0,
          loadMoreVideo: false,
        };
      });
      return obj;
    }
  };
  const saveData = () => {};

  const [loadedFeatures, setLoadedFeatures] = useState([]);

  const [features, setFeatures] = useState(null);
  // //("fea", features);
  const [loadedLevel1, setLoadedLevel1] = useState([]);
  const [loadedLevel2, setLoadedLevel2] = useState([]);
  const [loadedLevel3, setLoadedLevel3] = useState([]);
  const [level1, setLevel1] = useState(null);
  const [level2, setLevel2] = useState(null);
  const [level3, setLevel3] = useState(null);
  const [videos, setVideos] = useState({
    level: false,
    data: [],
    selected: "",
  });

  // Edit
  const [selectedNode, setSelectedNode] = useState({});
  const [editSubmit, setEditSubmit] = useState(false);
  const [featureEdit, setFeatureEdit] = useState([]);

  const fetchFeature = async (layer, role, appId) => {
    // //("hello", layer, role, appId);
    const obj = { ...features };
    obj[role].load = true;
    // setFeatures(obj);
    try {
      const { data } = await AxiosInstance.get(
        `/users/getFeature1/${role}/${null}`,
        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      );
      const finalData = data ? data?.features?.[0]?.features || [] : [];
      obj[role].data = finalData;
      obj[role].load = false;
      setFeatures(obj);
      setLoadedFeatures([...loadedFeatures, layer]);
      if (finalData.length > 0) {
        setLevel1(
          createLayer(
            level1,
            finalData.map(e => e["_id"])
          )
        );
      }
    } catch (error) {
      // //(error, "feature");
      const obj = { ...features };
      obj[role].load = null;
      obj[role].error = "Unable to fetch";
      setFeatures(obj);
    }
  };
  const fetchLevel1 = async (layer, feaId, role, app) => {
    // //(role);
    // //("fetching Level 1", layer, feaId, role, app);
    // //(id);
    const obj = { ...level1 };
    obj[feaId].loading = true;
    // //(obj, "LevelObj");

    setLevel1(obj);
    try {
      const { data } = await AxiosInstance.get(
        `/users/getSubfeature1/${feaId}/${role}/${null}`,

        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      );
      const finalData = data ? data?.subfeatures || [] : [];
      const obj = { ...level1 };
      obj[feaId].data = finalData;
      obj[feaId].loading = false;
      setLevel1(obj);
      setLoadedLevel1([...loadedLevel1, layer]);
      if (finalData.length > 0) {
        setLevel2(
          createLayer(
            level2,
            finalData.map(e => e["_id"])
          )
        );
      }
    } catch (error) {
      //(error);
      const obj = { ...level1 };
      obj[feaId].loading = null;
      obj[feaId].error = "Unable to fetch";
      setLevel1(obj);
    }
  };
  const fetchLevel2 = async (layer, featureId, id1, role, app) => {
    const obj = { ...level2 };
    obj[id1].loading = true;
    setLevel2(obj);
    try {
      const { data } = await AxiosInstance.get(
        `/users/getSubfeature2/${featureId}/${id1}/${role}/${null}`,
        // {
        //   role: feature,
        //   Application: "Qtalk",
        // },
        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      );
      const finalData = data ? data?.subfeatures1 || [] : [];
      const obj = { ...level2 };
      obj[id1].data = finalData;
      obj[id1].loading = false;
      setLevel2(obj);
      setLoadedLevel2([...loadedFeatures, layer]);
      if (finalData.length > 0) {
        setLevel3(
          createLayer(
            level3,
            finalData.map(e => e["_id"])
          )
        );
      }
    } catch (error) {
      //(error);
      const obj = { ...level2 };
      obj[id1].loading = null;
      obj[id1].error = "Unable to fetch";
      setLevel2(obj);
    }
  };
  const fetchLevel3 = async (layer, featureId, id1, id2, role, app) => {
    const obj = { ...level3 };
    obj[id2].loading = true;
    setLevel3(obj);
    try {
      const { data } = await AxiosInstance.get(
        `/users/getSubfeature3/${featureId}/${id1}/${id2}/${role}/${null}`,

        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      );
      const finalData = data ? data?.subfeatures2 || [] : [];
      const obj = { ...level3 };
      obj[id2].data = finalData;
      obj[id2].loading = false;
      setLevel3(obj);
      setLoadedLevel3([...loadedFeatures, layer]);
    } catch (error) {
      //(error);
      const obj = { ...level3 };
      obj[id2].loading = null;
      obj[id2].error = "Unable to fetch";
      setLevel3(obj);
    }
  };
  const fetchVideo = async (a, b, c, d, e) => {
    //("hiiiiiiiiiiiiiiiiiiiiiiiii");
    //(c, "c");
    //(d, "d");
    //(b, "b");
    //(a, "a");
    //(e, "e");
    let nameKey =
      e === "features"
        ? "features"
        : e === "subFeatures"
        ? "subFeatures"
        : e === "subFeatures1"
        ? "subFeatures1"
        : e === "subFeatures2"
        ? "subFeatures2"
        : "";
    try {
      const { data } = await AxiosInstance.post(
        `/video/getInformationAdmin`,
        {
          role: b,
          application: d,
          [nameKey]: c,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      );
      //(data?.videoApplications, "kkkkkkkkkkkkkkkk");

      setVideos({ data: data?.videoApplications, level: true, selected: e });
    } catch (error) {
      //(error);
    }
  };
  return (
    <PostContext.Provider
      value={{
        appData,
        setAppData,
        features,
        setFeatures,
        level1,
        setLevel1,
        level2,
        setLevel2,
        level3,
        setLevel3,
        createLayer,
        saveData,
        // role,
        loadedFeatures,
        setLoadedFeatures,
        loadedLevel1,
        setLoadedLevel1,
        loadedLevel2,
        setLoadedLevel2,
        loadedLevel3,
        setLoadedLevel3,
        fetchLevel3,
        fetchLevel2,
        fetchLevel1,
        fetchFeature,
        fetchRoleData,
        fetchVideo,
        videos,
        setVideos,
        appData,
        setAppData,
        RoleData,
        setRoleData,
        loadedRole,
        setLoadedRole,
        setSelectedNode,
        selectedNode,
        setEditSubmit,
        editSubmit,
        setFeatureEdit,
        featureEdit,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
