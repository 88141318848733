import React, { useState } from "react";
import styles from "./_adminlogin.module.css";
import {
  TextField,
  Button,
  Grid,
  Paper,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import AxiosInstance from "../../api/AxiosInstance";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-hot-toast";

const AdminLogin = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);


  const validateInputs = () => {
    let isValid = true;

    if (username.trim() === "") {
      setUsernameError(true);
      isValid = false;
    } else {
      setUsernameError(false);
    }

    if (password.trim() === "") {
      setPasswordError(true);
      isValid = false;
    } else {
      setPasswordError(false);
    }
    return isValid;
  };

  const handleUsernameChange = event => {
    setUsername(event.target.value);
    setUsernameError(false);
  };

  const handlePasswordChange = event => {
    setPassword(event.target.value);
    setPasswordError(false);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async e => {
    e.preventDefault();
    const isValid = validateInputs();

    if (isValid) {
      let payload = {
        email: username,
        password: password,
      };

      await AxiosInstance.post("/users/userLogin", payload)
        .then(x => {
          sessionStorage.setItem("user", JSON.stringify(x));

          window.location.assign("/admindashboard");
        })
        .catch(y => {
          toast.error(y?.response?.data?.message);
        });
    }
  };

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={styles.container}
    >
      <Paper className={styles.paper}>
        <Typography variant="h4" gutterBottom className={styles.Heading}>
          Sign in
        </Typography>
        <form onSubmit={e => handleLogin(e)}>
          <TextField
            label="Email"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            className={styles.textfield}
            value={username}
            onChange={handleUsernameChange}
            fullWidth
            error={usernameError}
            helperText={usernameError ? "Username is required" : ""}
          />

          <TextField
            label="Password"
            className={styles.textfield}
            type={showPassword ? "text" : "password"}
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            value={password}
            onChange={handlePasswordChange}
            error={passwordError}
            helperText={passwordError ? "Password is required" : ""}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={handleTogglePasswordVisibility}
                    edge="end"
                    aria-label="toggle password visibility"
                    size="small"
                    className={styles.iconeye}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            variant="contained"
            type="submit"
            fullWidth
            className={styles.LoginButton}
          >
            Login
          </Button>
        </form>
      </Paper>
    </Grid>
  );
};

export default AdminLogin;
