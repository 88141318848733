import React, { useState } from "react";
import { Box, Button, IconButton, Modal, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { Tooltip } from "@mui/material";
import AxiosInstance from "../../api/AxiosInstance";
import LogoutIcon from "@mui/icons-material/Logout";

// modal view style
const style3 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  height: 180,
  bgcolor: "#fff",
  boxShadow: "28px gray",
  p: 4,
  borderRadius: "30px",
  outline: "3px solid #2677B0",
  outlineOffset: "3px",
  border: "2px ridge gray",
};

const SuperLink = () => {
  let user1 = sessionStorage.getItem("user");
  let user2 = JSON.parse(user1);
  let user = user2?.data;

  const [openModal, setOpenModal] = useState(false);
  const handleLogout = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleConfirmLogout = async () => {
    let config = AxiosInstance.interceptors.request.use(res => {
      res.headers = {
        Authorization: `Bearer ${user?.TOKEN}`,
      };
      return res;
    });
    await AxiosInstance.post(`users/logoutUsers`, config)
      .then(data => {
        sessionStorage.removeItem("user");
        window.location.reload();
      })
      .catch(y => {
        console.log(y);
      });
    setOpenModal(false);
  };

  return (
    <>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        aria-labelledby="logout-modal"
        aria-describedby="logout-modal-description"
      >
        {/* Enhanced modal for warning */}
        <Box
          sx={{
            position: "absolute",
            width: 400,
            bgcolor: "background.paper",
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Typography
            id="logout-modal"
            variant="h6"
            component="h2"
            sx={{ mb: 2 }}
          >
            Warning: Do you want to logout?
          </Typography>
          <Button
            onClick={handleConfirmLogout}
            variant="contained"
            color="error"
            sx={{ mr: 2 }}
          >
            Yes, Logout
          </Button>
          <Button
            onClick={() => {
              setOpenModal(false);
            }}
            variant="outlined"
            type="button"
          >
            Cancel
          </Button>
        </Box>
      </Modal>
      <ul>
        <li>
          <NavLink
            activeclassname="true"
            to="/admindashboard"
            title="Home Page"
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            activeclassname="true"
            to="/admin/fetaureuploads"
            title="Features Page"
          >
            Features
          </NavLink>
        </li>
        <li>
          <NavLink
            activeclassname="true"
            to="admin/uploadvideo"
            title="Upload Video Page"
          >
            Upload Video
          </NavLink>
        </li>
        <li>
          <NavLink
            activeclassname="true"
            to="/admindashboard/adminregister"
            title="Upload Video Page"
          >
            Create admin
          </NavLink>
        </li>
        <li>
          <Tooltip
            title="Admin List"
            disableFocusListener
            disableTouchListener
            arrow
          >
            <NavLink to="admin/adminlist" activeclassname="true">
              {/* <i>
                <svg fill="#d3d3d3" height="1em" viewBox="0 0 512 512">
                  <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                </svg>
              </i> */}
              <span>Admin List</span>
            </NavLink>
          </Tooltip>
        </li>
        <li>
          <IconButton color="inherit" onClick={() => handleLogout()}>
            <LogoutIcon />{" "}
          </IconButton>
        </li>
      </ul>
    </>
  );
};

export default SuperLink;
