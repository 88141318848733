import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Fade,
  FormControl,
  Grid,
  Modal,
  TextField,
  Typography,
  Paper,
} from "@mui/material";
import AxiosInstance from "../../api/AxiosInstance";
import toast from "react-hot-toast";
import Style from "./uploader.module.css";
import { PostContext } from "./ListContextSuper";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/Cancel";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth < 520 ? "90%" : "60%",
  bgcolor: "background.paper",
  border: "1px solid #02476d",
  boxShadow: 24,
  p: 1,
  borderRadius: "6px",
};
const paperStyle = {
  padding: 20,
  gap: 0,
  width: "90%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  margin: "12vh auto",
};

const btnstyle = {
  margin: "8px 0",
  backgroundColor: "#015787",
  color: "#fff",
  fontWeight: "bold",
};
const gridContainer = {
  margin: "0 auto",
  // padding: "1em 0",
  gap: 20,
};
const EditVideoSuper = ({ edit, handleCloseEdit }) => {
  let user1 = sessionStorage.getItem("user");
  let user2 = JSON.parse(user1);
  let user = user2?.data;

  let { fetchVideo, selectedNode, RoleData, appData } = useContext(PostContext);

  const [selectedFileName, setSelectedFileName] = useState([]);
  let [disableSubmit, setDisableSubmit] = useState(false);
  let [app, setApp] = useState(edit.app);
  // let [weight, setWeight] = useState(edit.weight);
  let [role, setRole] = useState(edit.weight);
  let [application, setApplication] = useState([]);
  let [weight, setWeight] = useState(edit.weight);
  let [version, setVersion] = useState(edit.version);

  const [title, setTitle] = useState(edit.title);
  const [discription, setDiscription] = useState(edit?.description);

  let multiKeyWord = [];
  if (edit.keyword.length > 0) {
    edit.keyword.forEach(ele => {
      multiKeyWord.push(ele.keyword);
    });
  }

  console.log(multiKeyWord);
  console.log(edit.keyword.length > 0);
  // console.log(edit.keyword[0].keyword);
  const [keywords, setKeywords] = useState(
    multiKeyWord.length > 0
      ? multiKeyWord
      : [
          edit.keyword.length > 0
            ? edit.keyword.map((ele, ind) => ele.keyword)
            : "",
        ]
  );

  let handleChangeKeywords = (val, ind) => {
    const newKey = keywords.map((value, index) => {
      return index === ind ? val : value;
    });
    setKeywords(newKey);
  };

  let handleRemoveKeyWords = index => {
    const list = [...keywords];
    list.splice(index, 1);
    setKeywords(list);
  };

  let handleAddKeyWords = () => {
    setKeywords([...keywords, ""]);
  };

  const handleFileChange = event => {
    const selectedFile = Array.from(event.target.files);
    setSelectedFileName([selectedFile]);
  };
  let handleSubmit = async e => {
    e.preventDefault();
    setDisableSubmit(true);
    let formData = new FormData();
    if (selectedFileName.length <= 0) {
      try {
        let bca = selectedFileName[0];

        formData.append("videoname", title);

        // let feature = [{ _id: selectedOption._id }];

        const parts = edit.path.split("/public/videos");

        let nameKey =
          edit.selected === "features"
            ? "features"
            : edit.selected === "subFeatures"
            ? "subFeatures"
            : edit.selected === "subFeatures1"
            ? "subFeatures1"
            : edit.selected === "subFeatures2"
            ? "subFeatures2"
            : "";
        formData.append(nameKey, JSON.stringify(edit.ID));
        // formData.append("description", description);
        //  bca.forEach(file => {
        //    formData.append("videos", file);
        //  });

        formData.append("weight", weight);
        formData.append("description", discription);
        formData.append("appVersion", version);
        keywords.forEach((keyword, index) => {
          formData.append(`keyword`, keyword);
        });
        let rolee = edit?.data[0]?.role;

        let pathh = parts[0] === "" && parts.slice(1);
        await AxiosInstance.patch(
          `/video/editVideoDetail/${rolee}/${edit.app}${pathh}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${user?.TOKEN}`,
              "content-type": "multipart/form-data",
            },
          }
        )
          .then(response => {
            //(response);
            toast.success(
              response.message ? response.message : "Video added successfully"
            );
            handleCloseEdit();
            // y, role, featureId, appID, "features";

            fetchVideo(
              RoleData,
              selectedNode.role,
              edit?.ID[0]._id,
              selectedNode.appId,
              nameKey
            );
          })
          .catch(error => {
            //("1", error);
            if (error.response) {
              toast.error(error.response.data.error);
              setDisableSubmit(null);
            }
          });
      } catch (error) {
        toast.error(error);
      }
    } else {
      try {
        let bca = selectedFileName[0];

        formData.append("videoname", title);

        // let feature = [{ _id: selectedOption._id }];

        const parts = edit.path.split("/public/videos");

        let nameKey =
          edit.selected === "features"
            ? "features"
            : edit.selected === "subFeatures"
            ? "subFeatures"
            : edit.selected === "subFeatures1"
            ? "subFeatures1"
            : edit.selected === "subFeatures2"
            ? "subFeatures2"
            : "";
        formData.append(nameKey, JSON.stringify(edit.ID));
        // formData.append("description", description);
        bca.forEach(file => {
          formData.append("videos", file);
        });

        formData.append("weight", weight);
        formData.append("description", discription);
        formData.append("appVersion", version);
        keywords.forEach((keyword, index) => {
          formData.append(`keyword`, keyword);
        });
        let rolee = edit?.data[0]?.role;

        let pathh = parts[0] === "" && parts.slice(1);
        await AxiosInstance.patch(
          `/video/editVideo/${rolee}/${edit.app}${pathh}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${user?.TOKEN}`,
              "content-type": "multipart/form-data",
            },
          }
        )
          .then(response => {
            //(response);
            toast.success(
              response.message ? response.message : "Video added successfully"
            );
            handleCloseEdit();
            // y, role, featureId, appID, "features";

            fetchVideo(
              RoleData,
              selectedNode.role,
              edit?.ID[0]._id,
              selectedNode.appId,
              nameKey
            );
          })
          .catch(error => {
            //("1", error);
            if (error.response) {
              toast.error(error.response.data.error);
              setDisableSubmit(null);
            }
          });
      } catch (error) {
        toast.error(error);
      }
    }
  };
  return (
    // <aside
    //   style={{
    //     width: "100%",
    //     minHeight: "120px",
    //     position: "relative",
    //   }}
    // >
    <section style={{ padding: "0 5vw" }}>
      <Grid xs={12} item container style={gridContainer} align="center">
        {/* <Paper elevation={10} style={paperStyle}> */}
        <Grid
          align="center"
          item
          sx={{
            width: "100%",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            borderBottom: "1px solid #015787",
          }}
        >
          <span
            style={{ color: "#015787", fontSize: "20px", fontWeight: "bold" }}
          >
            Edit Video
          </span>
          <br />
        </Grid>

        <form action="" onSubmit={handleSubmit}>
          <Grid item container sm={12} spacing={2}>
            <Grid item sm={12}>
              <Box id={Style.formSubFields}>
                <FormControl sx={{ minWidth: 210 }} fullWidth>
                  <TextField
                    required
                    fullWidth
                    multiline
                    rows={1}
                    name="title"
                    label="Title"
                    type="text"
                    id="title"
                    value={title}
                    onChange={e => {
                      setTitle(e.target.value);
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "12px 12xpx", // Adjust padding to reduce height
                      },
                    }}
                  />
                </FormControl>
                <FormControl fullWidth sx={{ minWidth: 210 }}>
                  <TextField
                    required
                    fullWidth
                    name="weight"
                    label="Weight"
                    type="text"
                    id="weight"
                    value={weight}
                    onChange={e => {
                      setWeight(e.target.value);
                    }}
                    InputLabelProps={{
                      style: { fontSize: 14 },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "0px 0px", // Adjust padding to reduce height
                      },
                    }}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item sm={12}>
              <Box id={Style.formSubFields}>
                <FormControl fullWidth sx={{ minWidth: 210 }}>
                  <TextField
                    required
                    fullWidth
                    name="version"
                    label="version"
                    type="tel"
                    id="version"
                    value={version}
                    onChange={e => {
                      setVersion(e.target.value);
                    }}
                    InputLabelProps={{
                      style: { fontSize: 14 },
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "0px 0px", // Adjust padding to reduce height
                      },
                    }}
                  />
                </FormControl>
              </Box>
            </Grid>
            <Grid item sm={12}>
              {" "}
              <FormControl sx={{ minWidth: 210 }} fullWidth>
                <TextField
                  // required
                  fullWidth
                  multiline
                  rows={3}
                  name="description"
                  label="description"
                  type="text"
                  id="description"
                  value={discription}
                  onChange={e => {
                    setDiscription(e.target.value);
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      padding: "0px 0px", // Adjust padding to reduce height
                    },
                  }}
                />
              </FormControl>
            </Grid>
            <Box id={Style.formSubFields}></Box>
            <Grid sm={12} alignItems="center" sx={{ margin: 2 }}>
              <FormControl sx={{ minWidth: 210 }}>
                <input
                  type="file"
                  // accept="video/mp4"
                  name="selectedFileName"
                  // value={selectedFileName}
                  onChange={e => handleFileChange(e)}
                  style={{ display: "none" }}
                  id="video-upload-input"
                  // multiple
                />
                <label htmlFor="video-upload-input">
                  <Button
                    style={{
                      width: "200px",
                      height: "60px",
                      fontWeight: "bold",
                      color: "#015787",
                      border: "1px solid #015787",
                    }}
                    component="span"
                    variant="outlined"
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Video
                  </Button>
                </label>
                {selectedFileName && (
                  <p>{selectedFileName[0]?.map(x => x.name)}</p>
                )}
              </FormControl>
            </Grid>
            {keywords.map((item, index) => {
              return (
                <div
                  key={index + 1}
                  style={{ position: "relative", margin: "10px" }}
                >
                  <TextField
                    label={`Keyword ${index + 1}`}
                    style={{ marginBottom: "10px" }}
                    variant="outlined"
                    placeholder="Keyword"
                    required
                    fullWidth
                    value={item}
                    onChange={e => handleChangeKeywords(e.target.value, index)}
                    size="small"
                    sx={{
                      "& .MuiInputBase-root": {
                        padding: "8px 8px", // Adjust padding to reduce height
                      },
                    }}
                  ></TextField>
                  <div>
                    {keywords.length !== 1 && (
                      <button
                        title="Delete Keyword"
                        type="button"
                        className={Style.addBtn}
                        style={{
                          position: "absolute",
                          top: "-8px",
                          right: "-10px",
                          border: "none",
                          background: "transparent",
                        }}
                        onClick={() => handleRemoveKeyWords(index)}
                      >
                        <CancelIcon color="error" />
                      </button>
                    )}
                    {keywords.length - 1 === index && (
                      <button
                        title="Add more Keyword"
                        type="button"
                        className={Style.addBtn}
                        style={{
                          position: "absolute",
                          bottom: "0px",
                          right: "-17px",
                          border: "none",
                          background: "transparent",
                          color: "green",
                          display: keywords.length > 4 ? "none" : "block",
                        }}
                        onClick={handleAddKeyWords}
                      >
                        <AddCircleOutlineIcon />
                      </button>
                    )}
                  </div>
                </div>
              );
            })}
            <Grid item sm={12}>
              <Button
                type="submit"
                variant="contained"
                style={btnstyle}
                disabled={disableSubmit}
              >
                {disableSubmit ? "Adding video..." : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </form>
        {/* </Paper> */}
      </Grid>
    </section>
    // </aside>
  );
};

export default EditVideoSuper;
