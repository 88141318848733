import React from "react";

import Styles from "../_body.module.css";
import { Outlet } from "react-router-dom";

import VideosList from "./VideosList";

const AdminDashboard = () => {
  return (
    <div className={Styles.BottomBlock}>
      <aside style={{ display: "flex", height: "100vh" }}>
        <div style={{ width: "25vw" }}>
          <VideosList />
        </div>
        <div className={Styles.RightPart} >
          <Outlet />
        </div>
      </aside>
    </div>
  );
};

export default AdminDashboard;
