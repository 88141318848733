import React, { useContext, useEffect, useState } from "react";
import { PostContext } from "./VideoContext";
import videoImage from "../../assets/video1.webp";
import Styles from "./_mainUser.module.css";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Fade,
  Modal,
  Typography,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth < 520 ? "90%" : "60%",
  bgcolor: "background.paper",
  border: "1px solid #02476d",
  boxShadow: 24,
  p: 1,
  borderRadius: "6px",
};

const UserMainVideo = () => {
  const { videos } = useContext(PostContext);

  //Modal Block
  const [load, setLoad] = useState(false);
  const [open, setOpen] = React.useState(false);

  const [selectedVideo, setSelectedVideo] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // {
  //   videos.data[0].videos.map(e => (
  //     <video controls height="100px">
  //       <source src={`https://wikib.qspiders.com${e.path}`} />
  //     </video>
  //   ));
  // }

  return load ? (
    "loading"
  ) : videos.level ? (
    videos.data[0]?.video?.length > 0 ? (
      <div className={Styles.MainVideoBlockUser}>
        <h2
          style={{ textAlign: "center", marginBottom: "5%", color: "#02476d" }}
        >
          {/* <b>{videos.data[0].application}</b>&nbsp; */}
          {/* {videos.data[0]?.features[0][0]?.name} */}
        </h2>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <aside
                style={{
                  width: "100%",
                  minHeight: "120px",
                  height: "50vh",
                  position: "relative",
                }}
              >
                <button onClick={handleClose} className={Styles.closeButton}>
                  <svg width="18.54" height="20" viewBox="0 0 1216 1312">
                    <path
                      fill="crimson"
                      d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                    />
                  </svg>
                </button>
                {selectedVideo === null ? (
                  "Loading Video..."
                ) : (
                  <video
                    controls
                    autoPlay
                    width="100%"
                    style={{ height: "inherit" }}
                    controlsList="nodownload"
                    disablePictureInPicture
                  >
                    <source
                      src={`https://wikib.qspiders.com${selectedVideo.path}`}
                    />
                  </video>
                )}
              </aside>
            </Box>
          </Fade>
        </Modal>
        <aside
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            height: "75vh",
            overflow: "auto",
            width: "100%",
            // padding: "1%",
          }}
        >
          {videos.data[0].video[0].map((e, i) => {
            return (
              <Card key={i + 1} className={Styles.videoCardUser}>
                <CardMedia
                  sx={{
                    position: "relative",
                    height: 160,
                    backgroundImage: `url("https://wikib.qspiders.com${e?.thumbnail}")`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  title="Play Video"
                >
                  <Button
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    // variant="contained"
                    onClick={() => {
                      setSelectedVideo(e);
                      handleOpen();
                    }}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <svg width="50" height="50" viewBox="0 0 32 32">
                      <path
                        fill="#fff"
                        d="M11 23a1 1 0 0 1-1-1V10a1 1 0 0 1 1.447-.894l12 6a1 1 0 0 1 0 1.788l-12 6A1.001 1.001 0 0 1 11 23"
                      />
                      <path
                        fill="orangered"
                        d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2m7.447 14.895l-12 6A1 1 0 0 1 10 22V10a1 1 0 0 1 1.447-.894l12 6a1 1 0 0 1 0 1.788"
                      />
                    </svg>
                  </Button>
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom variant="body2" component="div">
                    <b style={{ color: "#02476d" }}>Name</b> : {e.videoname}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <b style={{ color: "#02476d" }}>Version</b> :{" "}
                    {videos.data[0]?.video[0][0]?.appVersion}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ height: "55px", overflowY: "auto" }}
                  >
                    <b
                      style={{
                        color: "#02476d",
                      }}
                    >
                      Description
                    </b>{" "}
                    : {e.description}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </aside>
      </div>
    ) : (
      <div className={Styles.noVideos}>
        <img src={videoImage} alt="" /> <h3>No Videos available</h3>
      </div>
    )
  ) : (
    <div className={Styles.selectFeatureInfo}>
      <h3>Select Tree Levels To View Video</h3>
    </div>
  );
};

export default UserMainVideo;
