import React from "react";
import { useSelector } from "react-redux";
import AdminLink from "./AdminLink";
import SuperLink from "./SuperLink";
import LogoutButton from "../admin/LogoutButton";

const Menu = () => {
  let user1 = sessionStorage.getItem("user");
  let user = JSON.parse(user1);
  //(user.role[0]);
  return user?.data?.role[0] === "Admin" ? (
    <AdminLink />
  ) : user?.data?.role[0] === "superadmin" ? (
    <SuperLink />
  ) : (
    <></>
    // <LogoutButton />
  );
};

export default Menu;
