import React, { useState } from "react";

import Styles from "../_body.module.css";
import { Outlet } from "react-router-dom";

import VideosListSuper from "./VideoListSuper";

const SuperAdminDashboard = () => {
  return (
    <div className={Styles.BottomBlock}>
      <aside style={{ display: "flex", height: "100vh" }}>
        <div style={{ width: "25vw" }}>
          <VideosListSuper />
        </div>
        <div className={Styles.RightPart}>
          <Outlet />
        </div>
      </aside>
    </div>
  );
};

export default SuperAdminDashboard;
