import React from "react";
import Styles from "../_body.module.css";
import AdminvideoPost from "./AdminvideoPost";

const UploadVideoDash = () => {
  return (
    <div className={Styles.BottomBlock}>
      <AdminvideoPost />
    </div>
  );
};

export default UploadVideoDash;
