import React, { useContext, useState } from "react";
import videoImage from "../../assets/video1.webp";
import Styles from "../user/_mainUser.module.css";

import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Fade,
  Modal,
  Typography,
} from "@mui/material";
import { PostContext } from "./ListContext";

import EditVideo from "./EditVideo";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: window.innerWidth < 520 ? "90%" : "60%",
  bgcolor: "background.paper",
  border: "1px solid #02476d",
  boxShadow: 24,
  p: 1,
  borderRadius: "6px",
};

const AdminVideo = () => {
  let { videos, appData } = useContext(PostContext);

  const [load, setLoad] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //Edit Video
  let [editData, setEditData] = useState([]);

  const [openEdit, setOpenEdit] = React.useState(false);
  const handleOpenEdit = () => setOpenEdit(true);
  const handleCloseEdit = () => setOpenEdit(false);

  let handleEdit = e => {
    handleOpenEdit();
  };

  return load ? (
    "loading"
  ) : videos?.level ? (
    videos?.data[0]?.video?.length > 0 ? (
      <div className={Styles.MainVideoBlockUser1}>
        <Modal
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            <Box sx={style}>
              <aside
                style={{
                  width: "100%",
                  minHeight: "120px",
                  height: "50vh",
                  position: "relative",
                }}
              >
                <button onClick={handleClose} className={Styles.closeButton}>
                  <svg width="18.54" height="20" viewBox="0 0 1216 1312">
                    <path
                      fill="crimson"
                      d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                    />
                  </svg>
                </button>
                {selectedVideo === null ? (
                  "Loading Video..."
                ) : (
                  <video
                    controls
                    autoPlay
                    width="100%"
                    style={{ height: "inherit" }}
                    controlsList="nodownload"
                    disablePictureInPicture
                  >
                    <source
                      src={`https://wikib.qspiders.com${selectedVideo.path}`}
                    />
                  </video>
                )}
              </aside>
            </Box>
          </Fade>
        </Modal>
        <Modal
          open={openEdit}
          onClose={handleCloseEdit}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={openEdit}>
            <Box sx={style}>
              <button onClick={handleCloseEdit} className={Styles.closeButton}>
                <svg width="18.54" height="20" viewBox="0 0 1216 1312">
                  <path
                    fill="crimson"
                    d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                  />
                </svg>
              </button>
              <EditVideo
                edit={editData}
                handleCloseEdit={handleCloseEdit}
                appData={appData}
              />
            </Box>
          </Fade>
        </Modal>
        <aside
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "10px",
            alignItems: "center",
            justifyContent: "center",
            height: "75vh",
            overflow: "auto",
            width: "100%",
            // padding: "1%",
          }}
        >
          {videos.data[0].video[0].map((e, i) => {
            //(videos.data[0]);
            return (
              <Card key={i + 1} className={Styles.videoCardUser}>
                <CardMedia
                  sx={{
                    position: "relative",
                    height: 160,
                    backgroundImage: `url("https://wikib.qspiders.com${e?.thumbnail}")`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                  title="Play Video"
                >
                  <Button
                    disableFocusRipple
                    disableRipple
                    disableTouchRipple
                    // variant="contained"
                    onClick={() => {
                      setSelectedVideo(e);
                      handleOpen();
                    }}
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <svg width="50" height="50" viewBox="0 0 32 32">
                      <path
                        fill="#fff"
                        d="M11 23a1 1 0 0 1-1-1V10a1 1 0 0 1 1.447-.894l12 6a1 1 0 0 1 0 1.788l-12 6A1.001 1.001 0 0 1 11 23"
                      />
                      <path
                        fill="orangered"
                        d="M16 2a14 14 0 1 0 14 14A14 14 0 0 0 16 2m7.447 14.895l-12 6A1 1 0 0 1 10 22V10a1 1 0 0 1 1.447-.894l12 6a1 1 0 0 1 0 1.788"
                      />
                    </svg>
                  </Button>
                </CardMedia>
                <CardContent>
                  <Typography gutterBottom variant="body2" component="div">
                    <b style={{ color: "#02476d" }}>Name</b> : {e.videoname}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <b style={{ color: "#02476d" }}>Version</b> : {e.appVersion}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="text.secondary"
                    style={{ height: "55px", overflowY: "auto" }}
                  >
                    <b
                      style={{
                        color: "#02476d",
                      }}
                    >
                      Description
                    </b>{" "}
                    : {e.description}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{ display: "flex", justifyContent: "end" }}
                    onClick={() => {
                      handleEdit(e);
                      setEditData({
                        ...videos,
                        path: e.path,
                        weight: e.weight,
                        title: e.videoname,
                        version: e.appVersion,
                        ID: videos?.data[0].id,
                        app: videos?.data[0]?.application,
                        description: e.description,
                        keyword: e.keywords,
                      });
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="17"
                      height="17"
                      viewBox="0 0 24 24"
                    >
                      <g
                        fill="none"
                        stroke="#14b84b"
                        stroke-linecap="round"
                        stroke-width="1.5"
                      >
                        <path d="M2 6c0 1.886 0 2.828.586 3.414C3.172 10 4.114 10 6 10h5c1.886 0 2.828 0 3.414-.586C15 8.828 15 7.886 15 6c0-1.886 0-2.828-.586-3.414C13.828 2 12.886 2 11 2H6c-1.886 0-2.828 0-3.414.586M21 18c0 1.886 0 2.828-.586 3.414C19.828 22 18.886 22 17 22h-3c-1.886 0-2.828 0-3.414-.586C10 20.828 10 19.886 10 18c0-1.886 0-2.828.586-3.414C11.172 14 12.114 14 14 14h3c1.886 0 2.828 0 3.414.586M6 2.5V10m7.5 4.5V22M11 2.5V10m6.5 4.5V22M2 6h4m9 0h-4m0 12h2.5m7.5 0h-3.5" />
                        <path
                          stroke-linejoin="round"
                          d="M21 11v-1c0-.932 0-2.398-.152-2.765a2 2 0 0 0-1.083-1.083C19.398 6 18.932 6 18 6m3 5l1-1m-1 1l-1-1M3 13v1c0 .932 0 2.398.152 2.765a2 2 0 0 0 1.083 1.083C4.602 18 5.068 18 6 18m-3-5l-1 1m1-1l1 1"
                        />
                      </g>
                    </svg>
                  </Typography>
                </CardContent>
              </Card>
            );
            // });
          })}
        </aside>
      </div>
    ) : (
      <div className={Styles.noVideos}>
        <img src={videoImage} alt="" /> <h3>No Videos available</h3>
      </div>
    )
  ) : (
    <div className={Styles.selectFeatureInfo}>
      <h3>Select Tree Levels To View Video</h3>
    </div>
  );
};

export default AdminVideo;
