import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import authService from "../../services/auth/AuthService";

let user1 = JSON.parse(sessionStorage.getItem("user"));

let initialState = {
  user: user1 ? user1 : null,
  isSuccess: false,
  isLoading: false,
  isError: false,
  isLoadingLogout: false,
  isLogoutAdminSuccess: false,
};

export const adminLogin = createAsyncThunk(
  "auth/admin/login",
  async (user, thunkAPI) => {
    try {
      return await authService.adminLogin(user);
    } catch (error) {
      const message = error.response;

      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Logout Admin
export const logOut = createAsyncThunk(
  "auth/logoutuser",
  async (_, thunkAPI) => {
    try {
      let token = thunkAPI.getState().auth.user.TOKEN;
      return await authService.logout(token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Slice starts here
export let authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: state => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.status = null;
      state.isLogoutAdminSuccess = false;
    },
  },
  extraReducers: builder => {
    builder.addCase(adminLogin.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(adminLogin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;

      state.user = payload;
      state.role = payload.role;
    });
    builder.addCase(adminLogin.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;

      state.isLogout = true;
    });
    //Admin Logout
    builder.addCase(logOut.pending, state => {
      state.isLoadingLogout = true;
    });
    builder.addCase(logOut.fulfilled, (state, { payload }) => {
      //(payload, "slice");
      state.isLoadingLogout = false;
      state.isLogoutAdminSuccess = true;

      state.user = null;
      state.message = payload;
    });
    builder.addCase(logOut.rejected, (state, { payload, status }) => {
      // state.isLoadingLogout = false;
      state.isLogoutAdminSuccess = false;
      state.message = payload;
      state.isErrorLogout = true;
    });
  },
});
export let { reset } = authSlice.actions;
export default authSlice.reducer;
