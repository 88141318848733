import React from "react";
import { AppBar } from "@mui/material";
import Styles from "../_body.module.css";
import { useLocation } from "react-router-dom";
import Logo from "./Logo";
import Menu from "./Menu";
import Style from "./navbar.module.css";

const Navbar = () => {
  const location = useLocation();
  const isHomePage = location.pathname === "/";
  let user1 = sessionStorage.getItem("user");
  let user = JSON.parse(user1);

  return (
    <AppBar position="static" className={Styles.Navbar}>
      {/* <Toolbar sx={{ justifyContent: "space-between" }}> */}
      <section id={Style.adminHeaderBlock}>
        <nav>
          <Logo />
          {!isHomePage &&
          (user?.data?.role[0] === "Admin" ||
            user?.data?.role[0] === "superadmin") ? (
            <Menu />
          ) : null}
        </nav>
      </section>

      {/* </Toolbar> */}
    </AppBar>
  );
};

export default Navbar;
// <>
//   <div className="navContent">
//     {/* <Fab variant="extended" onClick={() => setListSide(true)}> */}
//     <a> Home</a>
//     {/* </Fab> */}
//     {/* <Fab variant="extended" onClick={() => setListSide(false)}> */}
//     <a> List</a>
//     {/* </Fab> */}
//   </div>
//
// </>
