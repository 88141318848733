import React, { useEffect, useState } from "react";
import AxiosInstance from "../../api/AxiosInstance";
import CircularProgress from "@mui/material/CircularProgress";
import {
  Grid,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
} from "@mui/material";
import Style from "./_table.module.css";
import { styled, tableCellClasses, Button } from "@mui/material";
import toast from "react-hot-toast";
const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    // color: "#D35400",
    color: "#008394",
    fontWeight: "bold",
    fontSize: window.innerWidth > 1600 ? 16 : 14,
    backgroundColor: "#bbbaba",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: window.innerWidth > 1600 ? 14 : 12,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

const AdminList = () => {
  let user1 = sessionStorage.getItem("user");
  let user2 = JSON.parse(user1);
  let user = user2?.data;
  let [roleData, setRoleData] = useState([]);
  let [loadUser, setLoadUser] = useState(false);
  const [loadSearch, setLoadSearch] = useState(false);
  let [defaultStudents, setDefaultStudents] = useState([]);
  let [search, setSearch] = useState("");

  const [filtered, setFiltered] = useState({ x: false, term: "" });

  const [pagination, setPagination] = useState({
    limit: 20,
    page: 1,
    load: false,
    length: 1,
  });

  useEffect(() => {
    async function fetchData() {
      setLoadUser(true);
      try {
        let StudentData = await AxiosInstance.get(`/users/getAdminList`, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        });

        let data = StudentData?.data?.adminlist;
        setRoleData(data);

        setDefaultStudents(StudentData?.data);
        setLoadUser(false);

        setPagination({
          ...pagination,
          length: StudentData?.data?.totalCount,
          load: StudentData?.data?.totalCount > 10 ? false : null,
          page: 1,
          limit: StudentData?.data?.pageSize,
        });
      } catch (error) {
        setLoadUser(null);
      }
    }
    fetchData();
  }, []);

  let handleSearch = e => {
    setSearch(e.target.value);
  };
  // search changes
  const handleReset = () => {
    setPagination({
      ...pagination,
      length: defaultStudents.totalCount,
      load: defaultStudents.totalCount > 10 ? false : null,
      page: 1,
    });
    setRoleData(defaultStudents.adminlist);
    setFiltered({ x: false, term: "" });
    setSearch("");
  };
  const handleSearchClick = async () => {
    let searchVal = search === "" ? "" : `?search=${search}`;

    if (search.length > 2) {
      setLoadSearch(true);
      try {
        const { data } = await AxiosInstance.get(
          `/users/getAdminList${searchVal}`,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );

        setPagination({
          ...pagination,
          length: data.count,
          load: data.count > 10 ? false : null,
        });
        setRoleData(data.adminlist === null ? [] : data.adminlist);
        setLoadSearch(false);
        setFiltered({ x: true, term: search });
      } catch (error) {
        setLoadSearch(false);
        toast.error(error || "something went wrong");
      }
    } else {
      toast.error("search with minimum 3 characters");
      setLoadSearch(false);
    }
  };
  const handleChangePage = async (e, value) => {
    setSearch("");
    setPagination({ ...pagination, load: true });
    let scroolVal = value;
    try {
      let StudentData = await AxiosInstance.get(
        `/users/getAdminList?page=${scroolVal}`,
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      let Studentdataaa = StudentData?.data?.adminlist;
      setRoleData(Studentdataaa);
      setDefaultStudents(StudentData?.data);
      setLoadUser(false);
      setPagination({
        ...pagination,
        length: StudentData?.data?.totalCount,
        page: value,
        load: false,
      });
    } catch (error) {
      setLoadUser(null);
    }
  };
  return (
    <>
      {loadUser === null ? (
        <h3 style={{ width: "100%", textAlign: "center" }}>
          Something went wrong
        </h3>
      ) : loadUser ? (
        <div
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <Paper
          elevation={12}
          sx={{
            width: "98%",
            overflow: "hidden",
            margin: "0 auto",
            position: "relative",
          }}
          className={Style.studentListTable}
        >
          <TableContainer
            component={Paper}
            sx={{ height: "76vh", marginTop: "9vh" }}
          >
            <Grid
              container
              spacing={1}
              className={Style.filterBlock}
              alignItems="center"
            >
              <Grid item md={2} position="relative" sx={{ marginLeft: "5px" }}>
                <span className={Style.searchIcon}>
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="#fff"
                    stroke="#008394"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <circle cx="11" cy="11" r="8"></circle>
                    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                  </svg>
                </span>
                <input
                  placeholder="Search by email"
                  type="search"
                  className={Style.studentNameList}
                  value={search}
                  onChange={e => handleSearch(e)}
                />
              </Grid>
              <Grid item alignItems="center">
                &nbsp;
                <Button
                  size="small"
                  color="warning"
                  sx={{
                    background: "#008394",
                    color: "#fff",
                    fontWeight: "bold",
                    "&:hover": {
                      background: "#008394",
                      color: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={handleSearchClick}
                >
                  Search
                </Button>
                &nbsp;&nbsp;
                {filtered.x && (
                  <Button
                    sx={{ fontWeight: "bold" }}
                    size="small"
                    onClick={handleReset}
                    color="error"
                    variant="contained"
                  >
                    Reset
                  </Button>
                )}
              </Grid>
            </Grid>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell size="mideum" align="center">
                    Username
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    Email
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    Number
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    Branch
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    Application
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loadSearch ? (
                  <TableRow>
                    <TableCell
                      style={{ textAlign: "center", width: "100%" }}
                      colSpan={2}
                    >
                      Loading...
                    </TableCell>
                  </TableRow>
                ) : roleData?.length === 0 ? (
                  <StyledTableRow>
                    <TableCell align="center" colSpan={8}>
                      No Data Found
                    </TableCell>
                  </StyledTableRow>
                ) : (
                  roleData?.map((ele, ind) => {
                    return (
                      <StyledTableRow key={ind + 1}>
                        <StyledTableCell
                          align="center"
                          size="mideum"
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {ele.username}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          size="small"
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {ele.email}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          size="small"
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {ele.number}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          size="small"
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {ele.username}
                        </StyledTableCell>
                        <StyledTableCell
                          align="center"
                          size="small"
                          scope="row"
                          style={{
                            fontWeight: "bold",
                            textTransform: "capitalize",
                          }}
                        >
                          {ele.username}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {loadUser !== null &&
            pagination.load !== null &&
            pagination.length > 10 && (
              <Grid
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: "1%",
                  marginBottom: "1%",
                }}
              >
                <Pagination
                  count={Math.ceil(pagination?.length / pagination.limit)}
                  color="primary"
                  defaultPage={1}
                  disabled={pagination.load}
                  rowsperpage={pagination.limit}
                  siblingCount={1}
                  showLastButton={
                    Math.ceil(pagination.length / pagination.limit) > 5
                  }
                  showFirstButton={
                    Math.ceil(pagination.length / pagination.limit) > 5
                  }
                  page={pagination.page}
                  onChange={handleChangePage}
                />
              </Grid>
            )}
        </Paper>
      )}
    </>
  );
};

export default AdminList;
