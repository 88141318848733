import React from "react";
import { useState } from "react";
import styles from "./_form.module.css";
import { TextField, Autocomplete, FormControl } from "@mui/material";
import AxiosInstance from "../../api/AxiosInstance";
import toast from "react-hot-toast";
const AdminRegister = () => {
  const [appData, setAppData] = useState([]);
  const [values, setValues] = useState({
    username: "",
    email: "",
    number: "",
    role: "Admin",
    Application: "",
  });
  let user1 = sessionStorage.getItem("user");
  let user2 = JSON.parse(user1);
  let user = user2?.data;
  const [errors, setErrors] = useState({});
  const validateForm = () => {
    let newErrors = {};
    if (values.username < 0) {
      newErrors.username = "Username is required";
    } else if (values.username.length < 3) {
      newErrors.username = "Username must be at least 3 characters long";
    }
    if (!values.email) {
      newErrors.email = "Email address is required";
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      newErrors.email = "Email address is invalid";
    }

    if (values.number.length !== 10) {
      newErrors.number = "Number must be 10 digits";
    }
    if (!values.Application) {
      newErrors.Application = "Application is required";
    }

    setErrors(newErrors);
  };

  // fetching Application data get api
  const fetchAppData = async () => {
    try {
      await AxiosInstance.get(`/users/getApplication`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let roledata = data?.data?.getApplication;
          setAppData(roledata);
        })
        .catch(y => {
          console.log(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMenuOpenApp = () => {
    fetchAppData();
  };

  const handleChange = e => {
    const { name, value } = e.target;
    let sanitizedValue = value;
    if (name === "number") {
      sanitizedValue = sanitizedValue.replace(/\D/g, ""); // Remove non-digit characters
      sanitizedValue = sanitizedValue.slice(0, 10); // Limit to 10 digits
    }
    setValues(prevValues => ({ ...prevValues, [name]: sanitizedValue }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    validateForm();
    if (values.username.trim() === "") {
      toast.error("Please enter username");
    } else if (values.email.trim() === "") {
      toast.error("Please enter email");
    } else {
      try {
        let payload = {
          username: values.username.trim(),
          email: values.email.trim(),
          number: values.number,
          role: "Admin",
          Application: values.Application,
        };
        await AxiosInstance.post(`/users/userRegister`, payload, {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        })
          .then(data => {

            setValues({
              username: "",
              email: "",
              number: "",
              role: "",
              application: "",
            });
            toast.success("Admin Registered");
          })
          .catch(y => {
            toast.error(y.response.data.message);
          });
      } catch (error) {
        toast.error(error.message || "Error submitting form");
      }
    }
  };
  return (
    <section className={styles.RightBlock}>
      <form onSubmit={handleSubmit} className={styles.formblock}>
        <div>
          <h4 className={styles.heading}>Registration Form</h4>
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="username" className={styles.Headleable}>
            Username:
          </label>
          <input
            className={styles.inputField}
            type="text"
            id="username"
            name="username"
            placeholder="username"
            value={values.username}
            onChange={handleChange}
            required
          />
          {errors.username && (
            <div className={styles.error}>{errors.username}</div>
          )}
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="email" className={styles.Headleable}>
            Email:
          </label>
          <input
            className={styles.inputField}
            type="email"
            id="email"
            name="email"
            placeholder="email"
            value={values.email}
            onChange={handleChange}
            required
          />
          {errors.email && <div className={styles.error}>{errors.email}</div>}
        </div>
        <div className={styles.formGroup}>
          <label htmlFor="number" className={styles.Headleable}>
            Number:
          </label>
          <input
            className={styles.inputField}
            type="Number"
            id="number"
            name="number"
            placeholder="number"
            pattern="^[0-9]*"
            maxLength={10}
            title="Please enter only numbers"
            value={values.number}
            onChange={handleChange}
            required
          />

          {errors.number && <div className={styles.error}>{errors.number}</div>}
        </div>

        <div className={styles.formGroup}>
          <label htmlFor="number" className={styles.Headleable1}>
            Application:
          </label>
          <FormControl fullWidth>
            <Autocomplete
              freeSolo
              id="application"
              options={appData}
              onOpen={handleMenuOpenApp}
              value={values.Application}
              onChange={(event, newValue) => {
                setValues({ ...values, Application: newValue });
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Application"
              
                  error={Boolean(errors.application)}
                  helperText={errors.application}
                  required
                  sx={{
                    border: "2px solid #1976d2",
                    borderRadius: "4px",
                  }}
                />
              )}
            />
          </FormControl>
        </div>
        <button type="submit" className={styles.submitButton}>
          Submit
        </button>
      </form>
    </section>
  );
};

export default AdminRegister;
