import AxioInstance from "../../api/AxiosInstance";

// Admin Login
const adminLogin = async userData => {
  const { data } = await AxioInstance.post("/users/userLogin", userData);
  //(data);
  //(data);
  if (data) {
    // //(data);
    sessionStorage.setItem("user", JSON.stringify(data));
  }
  return data;
};
const logout = async token => {
  let config = AxioInstance.interceptors.request.use(res => {
    res.headers = {
      Authorization: `Bearer ${token}`,
    };
    return res;
  });
  const { data } = await AxioInstance.post("users/logoutUsers", config);

  if (data) {
    sessionStorage.removeItem("user");

  }
  return data;
};

const authService = {
  adminLogin,
  logout,
};

export default authService;
