import React from "react";
import { Navigate } from "react-router-dom";
const AdminRoutes = ({ children }) => {
  // let { user } = useSelector(state => state.auth);
  let user1 = sessionStorage.getItem("user");
  let user = JSON.parse(user1);

  if (user?.data?.TOKEN) {
    return <>{children}</>;
  } else {
    return <Navigate to="/" />;
  }
};

export default AdminRoutes;
