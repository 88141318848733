import React, { useEffect, useState } from "react";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";

import "../admin/listdrawer.css";

const FeaturesSidebar = () => {
  const location = useLocation();
  let [ready, setReady] = useState(false);
  let [activeMenu, setActiveMenu] = useState("/admin/fetaureuploads");

  useEffect(() => {
    setActiveMenu(location.pathname);
  }, [location]);
  let drawerClass = [];
  let mainClass = [];
  if (ready) {
    drawerClass.push("drawerOpen");
    mainClass.push("mainOpen");
  } else {
    drawerClass = [];
    mainClass.push("mainClose");
  }

  // To Fetch all the apis
  useEffect(() => {
    let checkService = async () => {
      try {
        setReady(true);
      } catch (error) {
        console.error(error);
      }
    };
    checkService();
  }, []);

  return (
    <div className="listApp">
      <nav>
        <span>
          <Tooltip
            title=" Click for Drawer's Menu to Expand/Collapse"
            disableFocusListener
            disableTouchListener
            arrow
          >
            <span
              className="icon-wrapper"
              onClick={() => {
                setReady(!ready);
              }}
            >
              <svg className="icon" viewBox="0 0 448 512">
                <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
              </svg>
            </span>
          </Tooltip>
        </span>
        {/* <div className="navContent">Feature</div> */}
        &nbsp;&nbsp;
      </nav>
      <aside className={drawerClass.join(" ")}>
        <ul>
          <li
            className={
              activeMenu === "/admin/fetaureuploads" ||
              activeMenu === "/admin/fetaureuploads/feature"
                ? "selectedMenu"
                : ""
            }
          >
            <Tooltip
              title="Add Feature"
              disableFocusListener
              disableTouchListener
              arrow
            >
              <Link
                to="/admin/fetaureuploads/feature"
                // activeclassname="true"
              >
                <i>
                  <svg fill="#d3d3d3" height="1em" viewBox="0 0 512 512">
                    <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                  </svg>
                </i>
                <span>Add Feature</span>
              </Link>
            </Tooltip>
          </li>
          <li
            className={
              activeMenu === "/admin/fetaureuploads/addsubfeature"
                ? "selectedMenu"
                : ""
            }
          >
            <Tooltip
              title="Add Sub feature Level 1"
              disableFocusListener
              disableTouchListener
              arrow
            >
              <Link
                to="/admin/fetaureuploads/addsubfeature"
                // activeclassname="true"
              >
                <i>
                  <svg fill="#d3d3d3" height="1em" viewBox="0 0 512 512">
                    <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                  </svg>
                </i>
                <span>Add Sub feature Level 1</span>
              </Link>
            </Tooltip>
          </li>
          <li
            className={
              activeMenu === "/admin/fetaureuploads/addsubfeature1"
                ? "selectedMenu"
                : ""
            }
          >
            <Tooltip
              title="Add Sub feature Level 2"
              disableFocusListener
              disableTouchListener
              arrow
            >
              <Link
                to="/admin/fetaureuploads/addsubfeature1"
                // activeclassname="true"
              >
                <i>
                  <svg fill="#d3d3d3" height="1em" viewBox="0 0 512 512">
                    <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                  </svg>
                </i>
                <span>Add Sub feature Level 2</span>
              </Link>
            </Tooltip>
          </li>
          <li
            className={
              activeMenu === "/admin/fetaureuploads/addsubfeature2"
                ? "selectedMenu"
                : ""
            }
          >
            <Tooltip
              title="Add Sub feature Level 3"
              disableFocusListener
              disableTouchListener
              arrow
            >
              <Link
                to="/admin/fetaureuploads/addsubfeature2"
                // activeclassname="true"
              >
                <i>
                  <svg fill="#d3d3d3" height="1em" viewBox="0 0 512 512">
                    <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
                  </svg>
                </i>
                <span>Add Sub feature Level 3</span>
              </Link>
            </Tooltip>
          </li>
        </ul>
      </aside>
      <main className={mainClass.join(" ")}>
        <Outlet />
      </main>
    </div>
  );
};

export default FeaturesSidebar;

// export default FeaturesSidebar
