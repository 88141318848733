import React from "react";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";
import { Button, Grid, TextField } from "@mui/material";
import Style from "./feedBack.module.css";

const DownloadShell = () => {
  return (
    <section className={Style.mainBlock}>
      <article>
        <h2 style={{ marginBottom: "20px" }}>Download the below application</h2>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button color="success" variant="contained">
            {" "}
            <a
              href="https://github.com/TechBhoomi/wiki-shell/releases/download/v-1.0.4/techbhoomi-app-Setup-1.0.4.exe"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              {" "}
              Click here to download the app
            </a>
          </Button>
        </Box>
        <form>
          <Box
            sx={{
              width: "80%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <ul className={Style.instructions}>
              <li>Open and install the application</li>
              <li>
                Login to the application which you prefer, then navigate to
                watch demo videos
              </li>
            </ul>
          </Box>
        </form>
      </article>
    </section>
  );
};

export default DownloadShell;
