import React from "react";
import UserVideoList from "./UserVideoList";
import UserMainVideo from "./UserMainVideo";
import Styles from "./_mainUser.module.css";
import { useState } from "react";

const UserDashboard = () => {
  const [sharedData, setSharedData] = useState(null);
  const [clicked, setClicked] = useState(false);

  const handleDataUpdate = newData => {
    // Update the shared data in the UserDashboard component
    setSharedData(newData);
  };
  return (
    <section id={Styles.MainUserBlock}>
      <div className={Styles.LeftSidebar}>
        <UserVideoList
          onDataUpdate={handleDataUpdate}
          setClicked={setClicked}
          clicked={clicked}
        />
      </div>
      <div className={Styles.RightVideoList}>
        <UserMainVideo
          sharedData={sharedData}
          setClicked={setClicked}
          clicked={clicked}
        />
      </div>
    </section>
  );
};

export default UserDashboard;
