import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";
import { Toaster } from "react-hot-toast";

import UserDashboard from "./components/user/UserDashboard";
import AddFeatures from "./components/admin/AddFeatures";

import AdminRegister from "./components/admin/AdminRegister";
import { PostContextProvider } from "./components/user/VideoContext";
import AddSubFeatureLevel1 from "./components/admin/AddSubFeatureLevel1";
import AddSubFeatureLevel2 from "./components/admin/AddSubFeatureLevel2";
import AddSubFeatureLevel3 from "./components/admin/AddSubFeatureLevel3";
import Sidebar from "./components/SideBar/Sidebar";
import { PostContextProvideradmin } from "./components/admin/ListContext";
import { PostContextProviderSumeradmin } from "./components/admin/ListContextSuper";

import AdminList from "./components/admin/AdminList";
import UploadVideoDash from "./components/admin/UploadVideoDash";
import AdminVideo from "./components/admin/AdminVideo";
import SuperAdminDashboard from "./components/admin/SuperAdminDashboard";
import AdminSuperVideo from "./components/admin/AdminSuperVideo";
import FeaturesSidebar from "./components/SideBar/FeaturesSidebar";
import AdminRoutes from "./components/AdminRoute/AdminRoutes";
import AdminDashboard from "./components/admin/AdminDashboard";
import AdminLogin from "./components/admin/AdminLogin";
import DownloadShell from "./components/user/DownloadShell";

const NotFound = React.lazy(() =>
  import("./components/pagenotfound/PageNotFound")
);

const App = () => {
  let [data, setData] = useState(null);
  let user1 = sessionStorage.getItem("user");

  useEffect(() => {
    let user = JSON.parse(user1);
    setData(user);
  }, [user1]);

  return (
    <Router>
      <Toaster position="top-center" reverseOrder={false} />
      <Navbar />

      <Suspense fallback={<div style={{ color: "red" }}>Loading...</div>}>
        <Routes>
          <Route path="/auth/admin/login" element={<AdminLogin />} />
          <Route path="/" element={<AdminLogin />} />
          <Route
            path="/admin/uploadvideo"
            element={
              <AdminRoutes>
                <UploadVideoDash />
              </AdminRoutes>
            }
          ></Route>
          <Route
            path="/admin/adminlist"
            element={
              <AdminRoutes>
                <AdminList />
              </AdminRoutes>
            }
          ></Route>
          <Route
            path="/admin/fetaureuploads"
            element={
              <AdminRoutes>
                <FeaturesSidebar />
              </AdminRoutes>
            }
          >
            <Route
              index
              element={
                <AdminRoutes>
                  <AddFeatures />
                </AdminRoutes>
              }
            />
            <Route
              path="/admin/fetaureuploads/feature/"
              element={
                <AdminRoutes>
                  <AddFeatures />
                </AdminRoutes>
              }
            />
            <Route
              path="/admin/fetaureuploadsaddsubfeature"
              element={
                <AdminRoutes>
                  <AddSubFeatureLevel1 />
                </AdminRoutes>
              }
            />
            <Route
              path="/admin/fetaureuploadsaddsubfeature1"
              element={
                <AdminRoutes>
                  <AddSubFeatureLevel2 />
                </AdminRoutes>
              }
            />
            <Route
              path="/admin/fetaureuploadsaddsubfeature2"
              element={
                <AdminRoutes>
                  <AddSubFeatureLevel3 />
                </AdminRoutes>
              }
            />
          </Route>
          <Route
            path="/admindashboard/adminregister"
            element={
              <AdminRoutes>
                <AdminRegister />
              </AdminRoutes>
            }
          ></Route>
          <Route
            path="/admindashboard"
            element={
              <AdminRoutes>
                {data && data?.data?.role[0] === "Admin" ? (
                  <PostContextProvideradmin>
                    <AdminDashboard />
                  </PostContextProvideradmin>
                ) : (
                  <PostContextProviderSumeradmin>
                    <SuperAdminDashboard />
                  </PostContextProviderSumeradmin>
                )}
              </AdminRoutes>
            }
          >
            <Route
              index
              element={
                <AdminRoutes>
                  {data?.data?.role[0] === "Admin" ? (
                    <AdminVideo />
                  ) : data?.data?.role[0] === "superadmin" ? (
                    <AdminSuperVideo />
                  ) : (
                    ""
                  )}
                </AdminRoutes>
              }
            />

            {/* <Route
              path="/admindashboard/uploadvideo"
              element={
                <AdminRoutes>
                  <AdminvideoPost />
                </AdminRoutes>
              }
            />
            <Route
              path="/admindashboard/addfeature"
              element={
                <AdminRoutes>
                  <AddFeatures />
                </AdminRoutes>
              }
            />
  
 
       
            */}
          </Route>
          {/* <Route path="/user/videoList/" element={<UserVideoList />} /> */}
          <Route
            path="/user/videoList/"
            element={
              <PostContextProvider>
                <UserDashboard />
              </PostContextProvider>
            }
          />
          <Route path="/user/Shell" element={<DownloadShell />} />
          {/* <Route path="/user/videoList/" element={<UserDashboard />} /> */}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
