import React, { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  Box,
  Autocomplete,
  Grid,
  Paper,
} from "@mui/material";
import styles from "./_videoPost.module.css";
import Styles from "./_adminlogin.module.css";
import AxiosInstance from "../../api/AxiosInstance";
import toast from "react-hot-toast";
import Loader from "../loader/Loader";

const AddSubFeatureLevel3 = () => {
  const [RoleData, setRoleData] = useState([]);
  const [appData, setAppData] = useState([]);
  const [featureData, setFeatureData] = useState([]);
  const [subFeatureData, setSubFeatureData] = useState([]);
  const [subFeatureLevel2Data, setSubFeatureLEvel2Data] = useState([]);
  let [loading, setLoading] = useState(false);

  let user1 = sessionStorage.getItem("user");
  let user2 = JSON.parse(user1);
  let user = user2?.data;

  const paperStyle = {
    padding: 20,
    gap: 0,
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "10px auto",
  };

  const gridContainer = {
    margin: "0 auto",
    padding: "1em 0",
    gap: 20,
  };
  let [feature, setFeature] = useState(null);
  let [role, setRole] = useState(null);
  let [sub_feature, setSub_feature] = useState(null);
  let [level2, setLevel2] = useState(null);
  let [level3, setLevel3] = useState("");

  const [initialValues, setInitialValues] = useState({
    application: "",
    weight: "",

    description: "",
  });

  const { application, weight, description } = initialValues;

  const handleChange = (name, value) => {
    setInitialValues({ ...initialValues, [name]: value });
  };

  // fetching Role data get api
  const fetchRoleData = async () => {
    let appName = application === "" ? "" : `?application=${application}`;

    try {
      await AxiosInstance.get(`/users/getRole${appName}`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let roledata = data?.data?.getrole;
          setRoleData(roledata);
        })
        .catch(y => {
          console.log(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMenuOpen = () => {
    fetchRoleData();
  };

  // fetching Application data get api
  const fetchAppData = async () => {
    try {
      await AxiosInstance.get(`/users/getApplication`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let roledata =
            user.role[0] === "Admin"
              ? data?.data.getApp
              : data?.data?.getApplication;
          if (typeof roledata === "string") {
            setAppData([roledata]);
          } else {
            setAppData(roledata);
          }
        })
        .catch(y => {
          console.log(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // fetch feature data
  const fetchFeatureData = async () => {
    try {
      let payload = {
        role: role,
        Application: application,
      };
      await AxiosInstance.get(`/users/getFeature1/${role}/${application}`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let featureData =
            data?.data?.features.length > 0
              ? data?.data?.features[0].features
              : [];
          //   setAppData(roledata);
          setFeatureData(featureData);
        })
        .catch(y => {
          //(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // fetch sub feature level 1 data
  const fetchSubFeature1Data = async () => {
    let fId = feature ? feature?._id : "";
    try {
      let payload = {
        role: role,
        Application: application,
      };
      await AxiosInstance.get(
        `/users/getSubfeature1/${fId}/${role}/${application}`,
        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      )
        .then(data => {
          let SubFeatureData =
            data?.data && data?.data?.subfeatures.length > 0
              ? data?.data?.subfeatures
              : [];
          setSubFeatureData(SubFeatureData);
        })
        .catch(y => {
          //(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // fetch sub feature level 2 data
  const fetchSubFeature1Level2Data = async () => {
    let fId = feature ? feature?._id : "";
    let fId2 = sub_feature ? sub_feature?._id : "";
    try {
      await AxiosInstance.get(
        `/users/getSubfeature2/${fId}/${fId2}/${role}/${application}`,

        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      )
        .then(data => {
          let SubFeatureData =
            data?.data && data?.data?.subfeatures1.length > 0
              ? data?.data?.subfeatures1
              : [];
          setSubFeatureLEvel2Data(SubFeatureData);
        })
        .catch(y => {
          console.log(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMenuOpenApp = () => {
    fetchAppData();
  };

  const handleMenuOpenFeature = () => {
    fetchFeatureData();
  };

  const handleMenuOpenSubFeature = () => {
    fetchSubFeature1Data();
  };
  const handleMenuOpenSubFeatureLevel2 = () => {
    fetchSubFeature1Level2Data();
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setLoading(true);

    if (application === "") {
      toast.error("Please select Application");
    } else if (role === "" || role === null) {
      toast.error("Please select Role");
    } else if (feature === null) {
      toast.error("Please select Feature");
    } else if (sub_feature === null) {
      toast.error("Please select Sub feature");
    } else if (level2 === null) {
      toast.error("Please select Sub feature Level 2");
    } else if (level3.trim() === "") {
      toast.error("Please enter Sub feature Level 3 title");
    } else if (weight === "") {
      toast.error("Please Enter weight");
    } else {
      let config = {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      };
      let formData = {
        role: role,
        Application: application,
        newSubFeature: {
          featureId: feature._id,
          subfeatureId: sub_feature._id,
          subFeatureName: level2.name,
          subFeatures2: [
            { name: level3.trim(), weight: weight, description: description },
          ],
        },
      };
      await AxiosInstance.post(
        `/users/addFeature?featureId=${feature._id}&subfeatureId=${sub_feature._id}`,
        formData,
        config
      )
        .then(res => {
          toast.success("Sub feature level 3 added successfully");
          setInitialValues({
            application: "",
            weight: "",
            description: "",
          });
          setRole(null);
          setFeature(null);
          setSub_feature(null);
          setLevel2(null);
          setLevel3("");
        })
        .catch(error => {
          toast.error("Error submitting form");
          setLoading(false);
        });
    }
    setLoading(false);
  };
  const preventMinus = e => {
    if (/[0-9]/.test(e.key)) {
      if (e.code === "Minus" || e.code === "NumpadSubtract") {
        e.preventDefault();
      } else if (e.target.value.length > 3) {
        e.preventDefault();
      } else if (e.target.value === "" && e.key === "0") {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const preventPasteNegative = e => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  return (
    <section style={{ padding: "0 5vw" }}>
      <Grid xs={12} item container style={gridContainer} align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <h2 style={{ color: "#015787" }}> Add Sub Feature Level 3</h2>
            <br />
          </Grid>
          <form className={styles.form} onSubmit={handleSubmit}>
            <Box className={styles.BoxStyle}>
              <FormControl fullWidth className={styles.selectField}>
                <Autocomplete
                  // disablePortal

                  id="application"
                  options={appData}
                  onOpen={handleMenuOpenApp}
                  value={initialValues.application}
                  onChange={(event, newValue) => {
                    handleChange("application", newValue);
                    setRole(null);
                    setFeature(null);
                    setSub_feature(null);
                    setLevel2(null);
                    setLevel3("");
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Application *" />
                  )}
                />
              </FormControl>
              <FormControl fullWidth className={styles.selectField}>
                {" "}
                <Autocomplete
                  // disablePortal

                  id="role"
                  disabled={application === "" || application === null}
                  options={RoleData}
                  onOpen={handleMenuOpen}
                  value={role}
                  onChange={(event, newValue) => {
                    setRole(newValue);
                    setFeature(null);
                    setSub_feature(null);
                    setLevel2(null);
                    setLevel3("");
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Role *" />
                  )}
                />
              </FormControl>
            </Box>
            <Box className={styles.BoxStyle}>
              <FormControl fullWidth className={styles.selectField}>
                <Autocomplete
                  // disablePortal

                  disabled={role === "" || role === null}
                  id="feature"
                  options={featureData}
                  getOptionLabel={option => option.name}
                  onOpen={handleMenuOpenFeature}
                  value={feature}
                  onChange={(event, newValue) => {
                    setFeature(newValue);
                    setSub_feature(null);
                    setLevel2(null);
                    setLevel3("");
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Feature *" />
                  )}
                />
              </FormControl>
            </Box>
            <Box className={styles.BoxStyle}>
              <FormControl fullWidth className={styles.selectField}>
                <Autocomplete
                  // disablePortal

                  disabled={feature === null}
                  id="sub_feature"
                  options={subFeatureData}
                  getOptionLabel={option => option.name}
                  onOpen={handleMenuOpenSubFeature}
                  value={sub_feature}
                  onChange={(event, newValue) => {
                    setSub_feature(newValue);
                    setLevel2(null);
                    setLevel3("");
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Sub Feature *" />
                  )}
                />
              </FormControl>
              <FormControl fullWidth className={styles.selectField}>
                <Autocomplete
                  // disablePortal

                  disabled={sub_feature === null}
                  id="level2"
                  options={subFeatureLevel2Data}
                  getOptionLabel={option => option.name}
                  onOpen={handleMenuOpenSubFeatureLevel2}
                  value={level2}
                  onChange={(event, newValue) => {
                    setLevel2(newValue);
                    setLevel3("");
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Sub Feature Level 2*" />
                  )}
                />
              </FormControl>
            </Box>

            <Box className={styles.BoxStyle}>
              <TextField
                fullWidth
                id="level3"
                name="level3"
                label="Sub Feature Level 3 Title *"
                type="text"
                value={level3}
                onChange={event => {
                  setLevel3(event.target.value);
                }}
                className={styles.formField}
                autoComplete="off"
              />

              <TextField
                fullWidth
                id="weight"
                name="weight"
                onPaste={preventPasteNegative}
                onKeyPress={preventMinus}
                label="Weight *"
                type="tel"
                value={initialValues.weight}
                onChange={event => {
                  handleChange("weight", event.target.value);
                }}
                className={styles.formField}
                autoComplete="off"
              />
            </Box>

            {loading ? (
              <Button className={Styles.LoginButtonLoader}>
                {" "}
                <Loader />
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                size="large"
                fullWidth
                className={Styles.LoginButton}
              >
                Submit
              </Button>
            )}
          </form>
        </Paper>
      </Grid>
    </section>
  );
};

export default AddSubFeatureLevel3;
