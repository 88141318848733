import { createContext, useState, useEffect } from "react";
import AxiosInstance, { VideoDemo } from "../../api/AxiosInstance";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";

export const PostContext = createContext();

export const PostContextProvider = ({ children }) => {
  let [appValue, setAppValue] = useState("");
  // Get the URLSearchParams object from the current URL
  useEffect(() => {
    // Get the URLSearchParams object from the current URL
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("app")) {
      const value = urlParams.get("app");
      setAppValue(value);
      sessionStorage.setItem("appValue", value);
    } else {
      let appValue = sessionStorage.getItem("appValue");
      setAppValue(appValue);
    }
  }, []); // Empty dependency array to run this effect only once on mount
  //(appValue);
  const navigate = useNavigate();
  // Use the 'appValue' as needed
  let [parsedData, setParseData] = useState(null);
  let [role, setRole] = useState([]);
  let [loadPage, setLoadPage] = useState(false);

  let [app, setApp] = useState({
    testfreshers: "videotoken",
    Qtalk: "QToken",
  });
  let token = Cookies.get(app[appValue], { domain: ".qspiders.com" });

  //   useEffect(() => {
  //     let sessionToken = sessionStorage.getItem('token');

  //     if (sessionToken) {
  //       console.log("already token stored")
  //     } else if(!sessionToken) {

  //   let token = Cookies.get(app[appValue], { domain: ".qspiders.com" });
  //       // Set data in sessionStorage
  //       sessionStorage.setItem('sessionStorage', token);

  // setToken(token)
  //     }

  //   },[app,appValue])
  // let token = Cookies.get(app[appValue], { domain: ".qspiders.com" });
  useEffect(() => {
    setLoadPage(true);
    if (token) {
      const handleButtonClick = async () => {
        try {
          // Make API call using Axios instance
          let payload = {
            token: token,
            domain:
              appValue === "testfreshers"
                ? "test-freshers"
                : appValue === "Qtalk"
                ? "q-talk"
                : "",
          };

          const response = await VideoDemo.post(
            "/video/validateToken",
            payload
          );
          if (response.status === 200) {
            setLoadPage(false);

            let data = response.data;
            setParseData(data);
            setRole(data.role);
          } else {
            // Handle API call failure
            console.error("API call failed");
            // You can show an error message or take appropriate action
          }
        } catch (error) {
          setLoadPage(null);
          console.log(error);
          console.error("Error occurred while making the API call", error);
          // toast.error(error.response.data.message);
          // navigate("/user/Shell");
          // Handle errors such as network issues, etc.
        }
      };
      handleButtonClick();
    }

    // Call handleButtonClick when needed, e.g., in a button click event handler
  }, [token]);
  useEffect(() => {
    let addFeature = () => {
      const obj = {};
      role.forEach(e => {
        obj[e] = {
          data: [],
          load: false,
          loadMore: false,
          error: null,
          length: 0,
          video: null,
        };
      });
      setFeatures(obj);
    };
    if (role.length > 0) {
      addFeature();
    }
  }, [role]);
  // var decoded = jwtDecode(parsedData?.TOKEN);

  // let role = parsedData?.role;

  // useEffect(() => {
  //   // Function to be executed every 24 hours
  //   const yourFunction = () => {
  //     if (user) {
  //       let user = Cookies.get("video");

  //       const parsedData = JSON?.parse(user);
  //       let data = Cookies.get("name");

  //       if (parsedData.application === "Qtalk" && data === undefined) {
  //         const otherProjectURL = `https://testchat.qspiders.com`; // Replace with your project's URL
  //         window.location.href = otherProjectURL;
  //       }
  //       // Your code here
  //     }
  //   };

  //   // Run the function initially
  //   yourFunction();

  //   // Set up an interval to run the function every 24 hours
  //   const intervalId = setInterval(yourFunction, 24 * 60 * 60 * 1000); // 24 hours in milliseconds
  //   // const intervalId = setInterval(yourFunction, 60 * 1000); // 24 hours in milliseconds

  //   // Clean up the interval on component unmount
  //   return () => clearInterval(intervalId);
  // }, []);
  const createLayer = (state, newKeys) => {
    if (state) {
      const uniqueKeys = newKeys.filter(e => !Object.keys(state).includes(e));
      const obj = {};
      uniqueKeys.forEach(e => {
        obj[e] = {
          data: [],
          loading: false,
          loadMore: false,
          error: null,
          length: 0,
          videos: [],
          loadMoreVideo: false,
          videoLength: 0,
        };
      });
      return { ...state, ...obj };
    } else {
      const obj = {};
      newKeys.forEach(e => {
        obj[e] = {
          data: [],
          loading: false,
          loadMore: false,
          error: null,
          length: 0,
          videos: [],
          videoLength: 0,
          loadMoreVideo: false,
        };
      });
      return obj;
    }
  };
  const saveData = () => {};

  const [loadedFeatures, setLoadedFeatures] = useState([]);
  const [features, setFeatures] = useState(null);
  const [loadedLevel1, setLoadedLevel1] = useState([]);
  const [loadedLevel2, setLoadedLevel2] = useState([]);
  const [loadedLevel3, setLoadedLevel3] = useState([]);
  const [level1, setLevel1] = useState(null);
  const [level2, setLevel2] = useState(null);
  const [level3, setLevel3] = useState(null);
  const [videos, setVideos] = useState({
    level: false,
    data: [],
    selected: "",
  });

  const fetchFeature = async (layer, feature) => {
    const encodedParamValue = encodeURIComponent(feature);
    const obj = { ...features };
    obj[feature].load = true;
    setFeatures(obj);
    try {
      const { data } = await AxiosInstance.get(
        `/users/getFeature1User?role=${encodedParamValue}`,

        {
          headers: {
            Authorization: `Bearer ${parsedData?.TOKEN}`,
          },
        }
      );

      const finalData = data ? data?.features?.[0]?.features || [] : [];
      const obj = { ...features };
      obj[feature].data = finalData;
      obj[feature].load = false;
      setFeatures(obj);
      setLoadedFeatures([...loadedFeatures, layer]);
      if (finalData.length > 0) {
        setLevel1(
          createLayer(
            level1,
            finalData.map(e => e["_id"])
          )
        );
      }
    } catch (error) {
      const obj = { ...features };
      obj[feature].load = null;
      obj[feature].error = "Unable to fetch";
      setFeatures(obj);
    }
  };
  const fetchLevel1 = async (layer, feature, id) => {
    const obj = { ...level1 };
    obj[id].loading = true;
    setLevel1(obj);
    try {
      const { data } = await AxiosInstance.get(
        `/users/getSubfeature1User/${id}?role=${feature}`,

        {
          headers: {
            Authorization: `Bearer ${parsedData?.TOKEN}`,
          },
        }
      );
      const finalData = data ? data?.subfeatures || [] : [];
      const obj = { ...level1 };
      obj[id].data = finalData;
      obj[id].loading = false;
      setLevel1(obj);
      setLoadedLevel1([...loadedLevel1, layer]);
      if (finalData.length > 0) {
        setLevel2(
          createLayer(
            level2,
            finalData.map(e => e["_id"])
          )
        );
      }
    } catch (error) {
      //(error);
      const obj = { ...level1 };
      obj[id].loading = null;
      obj[id].error = "Unable to fetch";
      setLevel1(obj);
    }
  };
  const fetchLevel2 = async (layer, feature, id1, id2) => {
    const obj = { ...level2 };
    obj[id2].loading = true;
    setLevel2(obj);

    try {
      const { data } = await AxiosInstance.get(
        `/users/getSubfeature2User/${feature}/${id2}?role=${id1}`,

        {
          headers: {
            Authorization: `Bearer ${parsedData?.TOKEN}`,
          },
        }
      );
      const finalData = data ? data?.subfeatures1 || [] : [];
      const obj = { ...level2 };
      obj[id2].data = finalData;
      obj[id2].loading = false;
      setLevel2(obj);
      setLoadedLevel2([...loadedFeatures, layer]);
      if (finalData.length > 0) {
        setLevel3(
          createLayer(
            level3,
            finalData.map(e => e["_id"])
          )
        );
      }
    } catch (error) {
      //(error);
      const obj = { ...level2 };
      obj[id2].loading = null;
      obj[id2].error = "Unable to fetch";
      setLevel2(obj);
      // toast.error()
    }
  };
  const fetchLevel3 = async (layer, feature, id1, id2, id3) => {
    const obj = { ...level3 };
    obj[id3].loading = true;
    setLevel3(obj);
    try {
      const { data } = await AxiosInstance.get(
        `/users/getSubfeature3User/${id1}/${id2}/${id3}?role=${feature}`,

        {
          headers: {
            Authorization: `Bearer ${parsedData?.TOKEN}`,
          },
        }
      );
      const finalData = data ? data?.subfeatures2 || [] : [];
      const obj = { ...level3 };
      obj[id3].data = finalData;
      obj[id3].loading = false;
      setLevel3(obj);
      setLoadedLevel3([...loadedFeatures, layer]);
    } catch (error) {
      //(error);
      const obj = { ...level3 };
      obj[id3].loading = null;
      obj[id3].error = "Unable to fetch";
      setLevel3(obj);
    }
  };
  const fetchVideo = async (a, b, c, d) => {
    //(c, "c");
    //(d, "d");
    //(b, "b");
    //(a, "a");
    let nameKey =
      d === "features"
        ? "features"
        : d === "subFeatures"
        ? "subFeatures"
        : d === "subFeatures1"
        ? "subFeatures1"
        : d === "subFeatures2"
        ? "subFeatures2"
        : "";
    try {
      const { data } = await AxiosInstance.post(
        `/video/getInformation`,
        {
          role: b,
          application: appValue,
          [nameKey]: c,
        },
        {
          headers: {
            Authorization: `Bearer ${parsedData?.TOKEN}`,
          },
        }
      );
      //("hello", data.videoApplications);
      setVideos({ data: data.videoApplications, level: true, selected: d });
    } catch (error) {
      //(error);
    }
  };
  return (
    <PostContext.Provider
      value={{
        features,
        setFeatures,
        level1,
        setLevel1,
        level2,
        setLevel2,
        level3,
        setLevel3,
        createLayer,
        saveData,
        role,
        loadedFeatures,
        setLoadedFeatures,
        loadedLevel1,
        setLoadedLevel1,
        loadedLevel2,
        setLoadedLevel2,
        loadedLevel3,
        setLoadedLevel3,
        fetchLevel3,
        fetchLevel2,
        fetchLevel1,
        fetchFeature,
        fetchVideo,
        videos,
        setVideos,
        loadPage,
        appValue,
        parsedData,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
