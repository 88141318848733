import TextField from "@mui/material/TextField";
import { useState } from "react";
import FormControl from "@mui/material/FormControl";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Button,
  Grid,
  Paper,
  Autocomplete,
  Box,
  CircularProgress,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import AxiosInstance from "../../api/AxiosInstance";
import { toast } from "react-hot-toast";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import Style from "./uploader.module.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

const UploadForm = props => {
  let navigate = useNavigate();
  const [RoleData, setRoleData] = useState([]);
  const [appData, setAppData] = useState([]);
  let [application, setApplication] = useState(null);
  let [appversion, setAppVersion] = useState("");
  let [weight, setWeight] = useState("");
  let [role, setRole] = useState(null);

  //* state and other data for keywords and source
  let multiKeyWord = [];

  const [keywords, setKeywords] = useState(
    multiKeyWord.length > 0 ? multiKeyWord : [""]
  );
  //(keywords);
  let handleChangeKeywords = (val, ind) => {
    const newKey = keywords.map((value, index) => {
      return index === ind ? val : value;
    });
    setKeywords(newKey);
  };

  let handleRemoveKeyWords = index => {
    const list = [...keywords];
    list.splice(index, 1);
    setKeywords(list);
  };

  let handleAddKeyWords = () => {
    setKeywords([...keywords, ""]);
  };
  const paperStyle = {
    padding: 15,
    gap: 0,
    width: "90%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    margin: "7vh auto",
  };

  const btnstyle = {
    margin: "8px 0",
    backgroundColor: "#015787",
    color: "#fff",
    fontWeight: "bold",
  };
  const gridContainer = {
    margin: "0 auto",
    padding: "1em 0",
    gap: 20,
  };

  let user1 = sessionStorage.getItem("user");
  let user2 = JSON.parse(user1);
  let user = user2?.data;

  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = useLocation();
  const sub_id = searchParams.get("sub_id");
  const top_id = searchParams.get("top");
  const chp_id = searchParams.get("chp_id");
  const st_id = searchParams.get("st_id");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [selectedFileName, setSelectedFileName] = useState([]);

  let [chapters, setChapters] = useState(
    chp_id === null ? null : state.chapter
  );
  let [chapterOption, setChapterOption] = useState([]);
  let [topics, setTopics] = useState(top_id === null ? null : state.topic);
  let [topicOption, setTopicOption] = useState([]);
  let [subTopics, setSubTopics] = useState(
    st_id === null ? null : state.sub_topic
  );
  let [subTopicOption, setSubTopicOption] = useState([]);
  let [disableSubmit, setDisableSubmit] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  //(typeof selectedFileName);

  const handleFileChange = event => {
    const selectedFile = Array.from(event.target.files);
    //(selectedFile);
    console.dir(selectedFile);
    setSelectedFileName([selectedFile]);
  };
  //! state for Feature dropdown
  const [subjectList, setSubjectList] = useState([]);
  const [selectedOption, setSelectedOption] = useState(
    sub_id === null ? null : state.subject
  );

  const handleMenuOpenFeature = () => {
    fetchOptions();
  };

  const fetchOptions = async () => {
    try {
      await AxiosInstance.get(`/users/getFeature1/${role}/${application}`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(response => {
          const data =
            response?.data?.features?.length > 0
              ? response?.data?.features[0]?.features
              : [];

          // Ensure data is an array
          if (Array.isArray(data)) {
            setSubjectList(data);
          } else {
            setSubjectList([]);
          }
        })
        .catch(y => {
          //(y)
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleOptionSelect = (_, newValue) => {
    setSelectedOption(newValue);
  };

  //!SubFeature
  const [loading1, setLoading1] = useState(false);

  const handleMenuOpenSubFeature = () => {
    fetchOptions1();
  };

  const handleOptionSelect1 = (_, newValue) => {
    setChapters(newValue);
    setTopics(null);
    setSubTopics(null);
  };
  const fetchOptions1 = async () => {
    setLoading1(true);
    try {
      let fId = selectedOption ? selectedOption?._id : "";
      const response = await AxiosInstance.get(
        `/users/getSubfeature1/${fId}/${role}/${application}`,
        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      ).then(response => {
        const data = response.data.subfeatures;
        // Ensure data is an array
        if (Array.isArray(data)) {
          setChapterOption(data);
        } else {
          setChapterOption([]);
        }
        setLoading1(false);
      });

      setLoading1(false);
    } catch (error) {
      setLoading1(false);
      console.error("Error fetching data:", error);
    }
  };
  //!Topics

  const [loading2, setLoading2] = useState(false);
  const handleMenuOpenSubFeatureLevel2 = () => {
    fetchOptions2();
  };

  const handleOptionSelect2 = (_, newValue) => {
    setTopics(newValue);
    setSubTopics(null);
  };
  const fetchOptions2 = async () => {
    let fId = selectedOption ? selectedOption?._id : "";
    let fId2 = chapters ? chapters?._id : "";
    setLoading2(true);
    try {
      await AxiosInstance.get(
        `/users/getSubfeature2/${fId}/${fId2}/${role}/${application}`,

        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      )
        .then(response => {
          const data = response.data.subfeatures1;
          if (Array.isArray(data)) {
            setTopicOption(data);
          } else {
            setTopicOption([]);
          }
          setLoading2(false);
        })
        .catch(y => {
          //(y);
        });
    } catch (error) {
      setLoading2(false);
      console.error("Error fetching data:", error);
    }
  };

  //!SubTopics

  const [loading3, setLoading3] = useState(false);


  let handlesubfeature3 = () => {
    fetchOptions3();
  };


  const handleOptionSelect3 = (_, newValue) => {
    setSubTopics(newValue);
  };
  const fetchOptions3 = async () => {
    setLoading3(true);
    try {
      let fId = selectedOption ? selectedOption?._id : "";
      let fId2 = chapters ? chapters?._id : "";
      let fId3 = topics ? topics?._id : "";
      await AxiosInstance.get(
        `/users/getSubfeature3/${fId}/${fId2}/${fId3}/${role}/${application}`,

        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      )
        .then(response => {
          const data =
            response?.data && response?.data?.subfeatures2.length > 0
              ? response?.data?.subfeatures2
              : [];
          console.log(response);
          console.log(data);
          if (Array.isArray(data)) {
            setSubTopicOption(data);
          } else {
            setSubTopicOption([]);
          }
          setLoading3(false);
        })
        .catch(y => {});
    } catch (error) {
      setLoading3(false);
      console.error("Error fetching data:", error);
    }
  };

  // fetching Role data get api
  const fetchRoleData = async () => {
    let appName = application === "" ? "" : `?application=${application}`;

    try {
      await AxiosInstance.get(`/users/getRole${appName}`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let roledata = data?.data?.getrole;
          setRoleData(roledata);
        })
        .catch(y => {
          //(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleMenuOpen = () => {
    fetchRoleData();
  };

  // fetching Application data get api
  const fetchAppData = async () => {
    try {
      await AxiosInstance.get(`/users/getApplication`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(data => {
          let roledata =
            user?.role[0] === "Admin"
              ? data?.data?.getApp
              : data?.data?.getApplication;
          if (typeof roledata === "string") {
            setAppData([roledata]);
          } else {
            setAppData(roledata);
          }
        })
        .catch(y => {
          //(y);
        });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handleMenuOpenApp = () => {
    fetchAppData();
  };

  let handleSubmit = async e => {
    //(selectedFileName);

    e.preventDefault();
    if (title.trim() === "") {
      toast.error("Please enter title");
    } else if (description.trim() === "") {
      toast.error("Please enter discription");
    } else if (selectedOption === null) {
      toast.error("Please select Feature");
    } else if (selectedFileName === null) {
      //("empty file");
      toast.error("Please select Video");
    } else {
      setDisableSubmit(true);

      let formData = new FormData();
      if (selectedFileName.length <= 0) {
        toast.error("no video attached to upload");
        setDisableSubmit(false);
        return null;
      }
      try {
        let bca = selectedFileName[0];
        formData.append("name", title);

        if (subTopics !== null) {
          let feature = [{ _id: subTopics._id }];

          formData.append("subFeatures2", JSON.stringify(feature));
        } else if (topics !== null) {
          let feature = [{ _id: topics._id }];

          formData.append("subFeatures1", JSON.stringify(feature));
        } else if (chapters !== null) {
          let feature = [{ _id: chapters._id }];

          formData.append("subFeatures", JSON.stringify(feature));
        } else {
          let feature = [{ _id: selectedOption._id }];


          formData.append("features", JSON.stringify(feature));
        }
        formData.append("description", description.trim());
        formData.append("videoname", title.trim());

        bca.forEach(file => {
          formData.append("videos", file);
        });
        formData.append("appVersion", appversion);
   
        formData.append("weight", weight);
        keywords.forEach((keyword, index) => {
          formData.append(`keyword`, keyword);
        });

      
        await AxiosInstance.post(
          `/video/create/${role}/${application}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${user?.TOKEN}`,
              "content-type": "multipart/form-data",
            },
          }
        )
          .then(response => {
            toast.success(
              response.message ? response.message : "Video added successfully"
            );
            setDisableSubmit(false);
            setApplication([]);
            setRole([]);
            setAppVersion("");
            setWeight("");
            setTitle("");
            setDescription("");
            setSelectedFileName(null);
            navigate("/admindashboard");
          })
          .catch(error => {

            if (error.response) {
              toast.error(error.response.data.error || "Something went wrong");
              setDisableSubmit(null);
            } else {
              setDisableSubmit(null);
              toast.error("Something went wrong");
            }
          });
      } catch (error) {


        toast.error(error);
        setDisableSubmit(uploadProgress > 0 ? null : false);
      }
    }
  };
  const preventMinus = e => {
    if (/[0-9]/.test(e.key)) {
      if (e.code === "Minus" || e.code === "NumpadSubtract") {
        e.preventDefault();
      } else if (e.target.value.length > 3) {
        e.preventDefault();
      } else if (e.target.value === "" && e.key === "0") {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const preventPasteNegative = e => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  return (
    <section style={{ padding: "0 5vw" }}>
      <Grid xs={12} item container style={gridContainer} align="center">
        <Paper elevation={10} style={paperStyle}>
          <Grid align="center" item>
            <h2 style={{ color: "#015787" }}>Add Video</h2>
            <br />
          </Grid>

          <form action="" onSubmit={handleSubmit}>
            <Grid item container sm={12} spacing={2} sx={{ padding: "10px" }}>
              <Grid item sm={12}>
                <Box id={Style.formSubFields}>
                  <FormControl fullWidth>
                    <Autocomplete
                      // disablePortal
                      required
                      id="application"
                      options={appData}
                      onOpen={handleMenuOpenApp}
                      value={application}
                      onChange={(event, newValue) => {
                        setApplication(newValue);
                        setRole(null);
                        setSelectedOption(null);
                        setChapters(null);
                        setSubTopics(null);
                        setTopics(null);
                      }}
                      renderInput={params => (
                        <TextField {...params} label="Application*" required />
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    {" "}
                    <Autocomplete
                      // disablePortal
                      required
                      id="role"
                      disabled={application === "" || application === null}
                      options={RoleData}
                      onOpen={handleMenuOpen}
                      value={role}
                      onChange={(event, newValue) => {
                        setRole(newValue);
                        setSelectedOption(null);
                        setChapters(null);
                        setSubTopics(null);
                        setTopics(null);
                        setSubTopics(null);
                      }}
                      renderInput={params => (
                        <TextField {...params} label="Role *" required />
                      )}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      required
                      fullWidth
                      name="appversion"
                      label="App Version"
                      type="text"
                      id="appversion"
                      value={appversion}
                      onChange={e => {
                        setAppVersion(e.target.value.trim());
                      }}
                      InputLabelProps={{
                        style: { fontSize: 14 },
                      }}
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      required
                      fullWidth
                      name="weight"
                      label="Weight"
                      type="tel"
                      id="weight"
                      onPaste={preventPasteNegative}
                      onKeyPress={preventMinus}
                      value={weight}
                      onChange={e => {
                        setWeight(e.target.value.trim());
                      }}
                      InputLabelProps={{
                        style: { fontSize: 14 },
                      }}
                    />
                  </FormControl>
                </Box>
              </Grid>
              <Box id={Style.formSubFields}>
                <FormControl fullWidth>
                  <Autocomplete
                    disabled={role === "" || role === null}
                    id="feature"
                    // open={open}
                    // onOpen={() => {
                    //   setOpen(true);
                    // }}
                    // onClose={() => {
                    //   setOpen(false);
                    // }}
                    options={subjectList}
                    value={selectedOption}
                    onOpen={handleMenuOpenFeature}
                    getOptionLabel={option => option.name}
                    onChange={(e, newvalue) => {
                      handleOptionSelect(e, newvalue);
                      setChapters(null);
                      setTopics(null);
                      setSubTopics(null);
                    }}
                    // inputValue={inputValue.toString()}
                    // onInputChange={handleInputChange}
                    renderInput={params => (
                      <TextField {...params} label="Feature *" />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Autocomplete
                    // open={open1}
                    // onOpen={() => {
                    //   setOpen1(true);
                    // }}
                    // onClose={() => {
                    //   setOpen1(false);
                    // }}
                    onOpen={handleMenuOpenSubFeature}
                    onChange={(e, value) => {
                      handleOptionSelect1(e, value);
                      setSubTopics(null);
                      setTopics(null);
                    }}
                    // inputValue={inputValue1.toString()}
                    // onInputChange={handleInputChange1}
                    loading={loading1}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    options={
                      chapterOption.length === 0
                        ? [{ name: "No SubFeature found" }]
                        : chapterOption
                    }
                    getOptionDisabled={() => chapterOption.length === 0}
                    getOptionSelected={option => chapters === option}
                    value={chapters}
                    disabled={!selectedOption}
                    name="Chapter"
                    getOptionLabel={option =>
                      option.name ? option.name : "No SubFeature found"
                    }
                    renderInput={params => (
                      <TextField {...params} label="Sub Feature *" />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Autocomplete
                    onOpen={handleMenuOpenSubFeatureLevel2}
                    onChange={(e, value) => {
                      handleOptionSelect2(e, value);
                      // setTopics(null);
                      setSubTopics(null);
                    }}
                    // inputValue={inputValue2.toString()}
                    // onInputChange={handleInputChange2}
                    loading={loading2}
                    options={
                      topicOption.length === 0
                        ? [{ name: "No SubFeature level 3 found" }]
                        : topicOption
                    }
                    getOptionDisabled={() => topicOption.length === 0}
                    getOptionSelected={option => topics === option}
                    value={topics}
                    disabled={chapters === null}
                    name="Sub Feature 2"
                    getOptionLabel={option =>
                      option.name ? option.name : "no SubFeature level 3 found"
                    }
                    renderInput={params => (
                      <TextField
                        {...params}
                        label={
                          chapters === null
                            ? "SubFeature level 2 not selected"
                            : "Level 2"
                        }
                        variant="outlined"
                        // required={defaultTopicList.length > 0}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <>
                              {loading2 ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Autocomplete
                    // open={open3}
                    // onOpen={() => {
                    //   setOpen3(true);
                    // }}
                    onOpen={handlesubfeature3}
                    // onClose={() => {
                    //   setOpen3(false);
                    // }}
                    onChange={(e, value) => {
                      handleOptionSelect3(e, value);
                      // setSubTopics(null);
                    }}
                    // inputValue={inputValue3.toString()}
                    // onInputChange={handleInputChange3}
                    loading={loading3}
                    // options={
                    //   subTopicOption.length === 0
                    //     ? [{ name: "No SubFeature level 3 found" }]
                    //     : subTopicOption
                    // }
                    options={subTopicOption}
                    getOptionDisabled={() => subTopicOption.length === 0}
                    getOptionSelected={option => subTopics === option}
                    disabled={topics === null}
                    value={subTopics}
                    name="Sub Feature 3"
                    getOptionLabel={option =>
                      option.name ? option.name : "SubFeature level 3 found"
                    }
                    renderInput={params => (
                      // <TextField
                      //   {...params}
                      //   label={
                      //     topics === null
                      //       ? "SubFeature level 3 not selected"
                      //       : "Search SubFeature level 3"
                      //   }
                      //   variant="outlined"

                      //   InputProps={{
                      //     ...params.InputProps,
                      //     endAdornment: (
                      //       <>
                      //         {loading3 ? (
                      //           <CircularProgress color="inherit" size={20} />
                      //         ) : null}
                      //         {params.InputProps.endAdornment}
                      //       </>
                      //     ),
                      //   }}
                      // />
                      <TextField {...params} label="Sub Feature Level 3*" />
                    )}
                  />
                </FormControl>
              </Box>
              <Grid sm={12} item alignItems="center">
                <FormControl sx={{ minWidth: 210 }}>
                  <input
                    type="file"
                    accept="video/mp4"
                    name="selectedFileName"
                    // value={selectedFileName}
                    onChange={e => handleFileChange(e)}
                    style={{ display: "none" }}
                    id="video-upload-input"
                    multiple
                  />
                  <label htmlFor="video-upload-input">
                    <Button
                      style={{
                        width: "200px",
                        height: "60px",
                        fontWeight: "bold",
                        color: "#015787",
                        border: "1px solid #015787",
                      }}
                      component="span"
                      variant="outlined"
                      startIcon={<CloudUploadIcon />}
                    >
                      Upload Video
                    </Button>
                  </label>

                  {selectedFileName && (
                    <p>{selectedFileName[0]?.map(x => x.name)}</p>
                  )}
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl sx={{ minWidth: 210 }} fullWidth>
                  <TextField
                    required
                    fullWidth
                    // multiline
                    // rows={1}
                    name="title"
                    label="Title"
                    type="text"
                    id="title"
                    value={title}
                    onChange={e => {
                      setTitle(e.target.value);
                    }}
                    InputLabelProps={{
                      style: { fontSize: 14 },
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl sx={{ minWidth: 210 }} fullWidth>
                  <TextField
                    required
                    fullWidth
                    multiline
                    rows={3}
                    id="description"
                    label="Description"
                    name="description"
                    value={description}
                    InputLabelProps={{
                      style: { fontSize: 14 },
                    }}
                    onChange={e => {
                      setDescription(e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              {keywords.map((item, index) => {
                return (
                  <div
                    key={index + 1}
                    style={{ position: "relative", margin: "10px" }}
                  >
                    <TextField
                      label={`Keyword ${index + 1}`}
                      style={{ marginBottom: "10px" }}
                      variant="outlined"
                      placeholder="Keyword"
                      required
                      fullWidth
                      value={item}
                      onChange={e =>
                        handleChangeKeywords(e.target.value, index)
                      }
                      size="small"
                    ></TextField>
                    <div>
                      {keywords.length !== 1 && (
                        <button
                          title="Delete Keyword"
                          type="button"
                          className={Style.addBtn}
                          style={{
                            position: "absolute",
                            top: "-8px",
                            right: "-10px",
                            border: "none",
                            background: "transparent",
                          }}
                          onClick={() => handleRemoveKeyWords(index)}
                        >
                          <CancelIcon color="error" />
                        </button>
                      )}
                      {keywords.length - 1 === index && (
                        <button
                          title="Add more Keyword"
                          type="button"
                          className={Style.addBtn}
                          style={{
                            position: "absolute",
                            bottom: "0px",
                            right: "-17px",
                            border: "none",
                            background: "transparent",
                            color: "green",
                            display: keywords.length > 5 ? "none" : "block",
                          }}
                          onClick={handleAddKeyWords}
                        >
                          <AddCircleOutlineIcon />
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
              <Grid item sm={12}>
                <Button
                  type="submit"
                  variant="contained"
                  style={btnstyle}
                  disabled={disableSubmit}
                >
                  {disableSubmit ? "Adding video..." : "Submit"}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </section>
  );
};

export default UploadForm;
